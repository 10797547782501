import React, { useState, useEffect } from 'react';

import { Button, Card, CardBody, Table, Row, Col, Badge, Tooltip } from 'reactstrap';

import moment from 'moment';

const pageSize = 5;

const Finance = (props) => {

  const [ paymentMethods, setPaymentMethods ] = useState([]);

  const [ transactions, setTransactions ] = useState([]);
  const [ firstTransaction, setFirstTransaction ] = useState();
  const [ lastTransaction, setLastTransaction ] = useState();

  const [ toolTipCredit, setToolTipCredit ] = useState(false);

  const getTransactionText = (entry) => {
    let text = ''
    switch(entry) {
      case 'stripePayment':
        text = 'Kreditkartenzahlung';
        break;
      case 'slotBookings':
        text = 'Screenbuchung';
        break;
      case 'slotBookingsDeclined':
        text = 'Screenbuchung abgelehnt';
        break;
      case 'dealBooking':
        text = 'Dealbuchung';
        break;
      case 'dealBookingDeclined':
        text = 'Dealbuchung abgelehnt';
        break;      
    }
    return text;
  }

  useEffect(() => {
    const unsubscribe = props.firebase.db.collection('billingAccounts').doc(props.user?.id).collection('paymentMethods').onSnapshot(snap => {
      let pMethods = [];
      snap.forEach((doc) => {
        const paymentMethod = doc.data();
        if (!paymentMethod.card) {
          return;
        }
        pMethods.push({id: doc.id, pmid: paymentMethod.id,
                        cardNr: "**** **** **** " + paymentMethod.card?.last4,
                        cardExp: paymentMethod.card.exp_month?.toLocaleString(undefined, {minimumIntegerDigits: 2}) + "/" + paymentMethod.card.exp_year?.toString(),
                      })
      })
      setPaymentMethods(pMethods)
    });
    return () => unsubscribe();
  }, []);

  const getInitialTransactions = async() => {
    const snap = await props.firebase.db.collection('billingAccounts').doc(props.user?.id).collection('transactions').orderBy('tStamp', 'desc').limit(pageSize).get();
    const trns = [];
    snap.forEach(s => {
      trns.push({ref: s, id: s.id, ...s.data()})
    });
    setTransactions(trns);
    setFirstTransaction(trns[0]?.ref)

    // get last element in collection
    const lastSnap = await props.firebase.db.collection('billingAccounts').doc(props.user?.id).collection('transactions').orderBy('tStamp', 'asc').limit(1).get();
    lastSnap.forEach(s => {
      setLastTransaction(s);
    })
  }

  const financePaginationChange = async(direction) => {
    let snap = [];
    if(direction === 'next') {
      const lastElement = transactions[transactions.length - 1]?.ref;
      snap = await props.firebase.db.collection('billingAccounts').doc(props.user?.id).collection('transactions').orderBy('tStamp', 'desc').startAfter(lastElement).limit(pageSize).get();
    } else if (direction === 'previous') {
      const firstElement = transactions[0]?.ref;
      snap = await props.firebase.db.collection('billingAccounts').doc(props.user?.id).collection('transactions').orderBy('tStamp', 'desc').endBefore(firstElement).limit(pageSize).get();
    }

    if(snap?.size) {
      let trns = [];
      snap.forEach(s => {
        trns.push({ref: s, id: s.id, ...s.data()})
      })
      setTransactions(trns);
    }
  }

  useEffect(() => {
    getInitialTransactions();
  }, []);

  return(
    <Card className="my-2 shadow">  
      <CardBody>
        <div className="d-flex">
          <h5 className="flex-grow-1"><i className="fas fa-wallet"></i> Finanzen</h5>
        </div>  
        <div className="mt-2">
          <Row>
            <Col lg="4" sm="6">
              <Card>
                <CardBody className="text-center">
                  <h2>{props.credit && props.credit?.toFixed(2)} CHF</h2>
                  Guthaben
                  <span id="ttCredit"> <i className="fas fa-info-circle text-primary"></i></span>
                  <Tooltip placement="auto" isOpen={toolTipCredit} target="ttCredit" toggle={() => setToolTipCredit(!toolTipCredit)}>
                  Sofern Deine Bookings vom Publisher abgelehnt wurden oder Du einen Rabatt-Code/Gutschein erhalten hast, siehst Du den entsprechenden Betrag im Feld links. Dieser Betrag wird Dir automatisch bei der nächsten Buchung angerechnet resp. vom Kaufpreis abgezogen. Bitte kontaktiere bei Fragen und Unklarheiten oder wenn Du eine direkte Auszahlung Deines Guthaben wünschst <a href="mailto:feedback@biddad.com">feedback@biddad.com</a>.
                  </Tooltip>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
          Zahlungsmethoden:
          <Table>
            <thead>
              <tr>
                <th>Kartennummer</th>
                <th>Gültig bis</th>
              </tr>
            </thead>
            <tbody>
              {paymentMethods.map(pM => 
                <tr key={pM.id}>
                  <td>{pM.cardNr}</td>
                  <td>{pM.cardExp}</td>
                </tr>  
              )}
            </tbody>
          </Table>
          Transaktionen:
          <Table>
            <thead>
              <tr>
                <th>Datum</th>
                <th></th>
                <th>Beschreibung</th>
                <th>Betrag</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map(p => 
                <tr key={p.id}>
                  <td>{String(moment(p.tStamp.toDate()).format('DD.MM.YYYY HH:mm:ss'))}</td>
                  <td><Badge color={p.type === 'credit' ? 'success' : (p.type === 'debit' ? 'warning' : '')}>{p.type === 'credit' ? 'Gutschrift' : (p.type === 'debit' ? 'Belastung' : '')}</Badge></td>
                  <td>{getTransactionText(p.entry)}</td>
                  <td>{(p.type === 'debit' ? "-" : "") + p.amount?.toFixed(2) + " CHF"}</td>
                </tr>  
              )}
            </tbody>
          </Table>
          <div className="float-right">
            <Button color="link" disabled={transactions[0]?.ref?.id === firstTransaction?.id} className="btn-thin" onClick={() => financePaginationChange('previous')}><i className="fas fa-chevron-left"></i></Button>
            <Button color="link" disabled={transactions[transactions.length - 1]?.ref?.id === lastTransaction?.id} className="btn-thin" onClick={() => financePaginationChange('next')}><i className="fas fa-chevron-right"></i></Button>
          </div>
        </div>
        

      </CardBody>
    </Card>   
  )
}

export default Finance;


/*

- c: billing_accounts
  - d: <ID>
    stripe_customer_id
    stripe_setup_secret
    credit


*/