import React from 'react';

import { ClipLoader } from 'react-spinners';

import { Button, ModalBody, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Col, FormGroup } from 'reactstrap';

import { GAEvent } from '../../GA/tracker';

const initialState = {
  email: '',
  error: null,
  linkSent: false,
  loading: false,
}

class PasswordForget extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
  }

  onSubmit = event => {
    event.preventDefault();
    this.setState({ loading: true });
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({linkSent: true, loading: false});
        GAEvent('auth', 'Password forget link sent');
      })
      .catch(error => {
        this.setState({ error, loading: false });
        GAEvent('auth', 'Error during password forget link generation');
      });
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {

    const { email, error, linkSent, loading } = this.state;
    const isInvalid = email === '';

    return (
      
      <div>
        <ModalBody>
          {linkSent ? <p>A reset link has been sent to your email address. Please check your inbox and proceed to <Button color="link" onClick={() => this.props.changeMode("login")}>login</Button></p> :
          <Form onSubmit={this.onSubmit}>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><i className="fas fa-envelope"></i></InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="email"
                    value={email}
                    onChange={this.onChange}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              {loading ? <Button className="btn-block my-2" disabled={true}><ClipLoader sizeUnit={"px"} size={50} color={'#123abc'} loading={loading} /></Button> : <Button className="btn-block my-2" disabled={isInvalid}>Reset My Password</Button>}
              {error && <p>{error.message}</p>}
            </Col>
          </Form>
        }
          </ModalBody>
      </div>
    )
  }
}

export default PasswordForget;