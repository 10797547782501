import React from 'react';

import { Button } from 'reactstrap';

const SelectSlot = () => {

  return (
    <div>
      <center>
      Nun haben Sie die Möglichkeit Slots auszuwählen, bzw. wann Ihre Inhalte ausgestrahlt werden sollen. Indem Sie links auf das Kalender-Symbol drücken, können Sie sich weitere Slots anzeigen lassen. <br />
      Sie können direkt auf die Slots klicken. Alternativ können Sie rechts auch den Button «Alle auswählen» betätigen.
      <img  src="/GettingStarted/selectSlot.png" className="img-fluid mx-auto d-block my-2" />
      </center>
      
    </div>
  )
}

export default SelectSlot;