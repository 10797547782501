import React, { useState, useEffect } from 'react';

import { Button, ButtonGroup, Card, CardBody, Form, FormGroup, Label, Input, Col, Row, Table } from 'reactstrap';

import Stripe from './stripe';
import Loading from '../Loading';

import { withFirebase } from '../Firebase';

/* Props:
- uid
- cart
- total

*/

const initialAddress = {
  company: '',
  street: '',
  zip: '',
  city: '',
}

const Checkout = (props) => {

  const [ paymentOptions, setPaymentOptions ] = useState([]);
  const [ selectedPaymentOption, setSelectedPaymentOption ] = useState('card');

  const [ address, setAddress ] = useState(initialAddress);
  const [ newAddress, setNewAddress ] = useState(initialAddress);
  const [ loadingAddress, setLoadingAddress ] = useState(false);
  const [ editModeAddress, setEditModeAddress ] = useState(false);

  const [ selectedPaymentMethod, setSelectedPaymentMethod ] = useState();

  const [ credit, setCredit ] = useState(0);

  const [ agbsAccepted, setAgbsAccepted ] = useState(false);

  const onAddressChange = event => {
    if(event.target.name === 'private') {
      if(newAddress?.company === '-' ) {
        setNewAddress({...newAddress, company: ''});
      } else {
        setNewAddress({...newAddress, company: '-'});
      }
    } else {
      setNewAddress({...newAddress, [event.target.name]: event.target.value});
    }
  };

  const saveNewAddress = async() => {
    setLoadingAddress(true);
    await props.firebase.db.collection('billingAccounts').doc(props.uid).update(newAddress);
    setEditModeAddress(false)
    setLoadingAddress(false);
  };

  const getPaymentOptionButton = (option) => {
    let icon = null;
    let text = null;
    if(option === 'card') {
      icon = 'fas fa-credit-card';
      text = 'Kreditkarte';
    } else if (option === 'credit') {
      icon = 'fas fa-file-invoice';
      text = 'Rechnung';
    }
    if(icon && text) return <Button key={option} color="link" outline={selectedPaymentOption===option} className="btn-thin" onClick={() => setSelectedPaymentOption(option)}><i className={icon}></i> {text}</Button>
  };

  const readyToComplete = address?.company && address?.street && address?.zip && address?.city &&
                          (selectedPaymentOption === 'credit' || (selectedPaymentOption === 'card' && selectedPaymentMethod) || (props.totalMwst - credit) > 0) &&
                          agbsAccepted;

  const btnConfirm = async() => {
    props.setLoading(true);
    if(selectedPaymentOption === 'card' && (props.totalMwst - credit) > 0) {
      await props.firebase.db.collection('carts').doc(props.uid).update({
        status: 'paying',
        paymentOption: selectedPaymentOption,
        paymentMethod: selectedPaymentMethod,
      })
    } else {
      await props.firebase.db.collection('carts').doc(props.uid).update({
        status: 'booking',
        paymentOption: selectedPaymentOption,
      })
    }
  }

  useEffect(() => {
    (!address?.company || !address?.street || !address?.zip || !address?.city) ? setEditModeAddress(true) : setEditModeAddress(false);
    setNewAddress(address);
  }, [address]);

  useEffect(() => {
    setLoadingAddress(true);
    const unsubscribe = props.firebase.db.collection('billingAccounts').doc(props.uid).onSnapshot(snap => {
      const data = snap.data();
      setAddress({company: data.company || '',
                  street: data.street || '',
                  zip: data.zip || '',
                  city: data.city || ''});
      setPaymentOptions(data.paymentOptions || []);
      setLoadingAddress(false);
      setCredit(data.credit);
    });
    return () => unsubscribe();
  }, []);

  return(
    <>
      <Card className="my-2 shadow">
        <CardBody>
          <div className="d-flex">
            <h6 className="text-secondary flex-grow-1" >Rechnungsadresse</h6>
            <h6 className="float-right"><Button color="link" onClick={() => setEditModeAddress(!editModeAddress)} disabled={!newAddress.company || !newAddress.street || !newAddress.zip || !newAddress.city}><i className="fas fa-pen text-primary" /></Button></h6>
          </div>
          {loadingAddress ?
            <Loading /> :
            (editModeAddress ?
              <>
              <Form>
                <FormGroup row>
                  <Label for="company" sm={2}>Firma</Label>
                  <Col sm={7}>
                    <Input type="text" name="company" id="company" value={newAddress?.company} disabled={newAddress?.company === '-'} onChange={onAddressChange} />
                  </Col>
                  <Col sm={3}>
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" name="private" id="private" checked={newAddress?.company === '-'} onChange={onAddressChange} />{' '}
                        Privat
                      </Label>
                    </FormGroup>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="street" sm={2}>Strasse</Label>
                  <Col sm={10}>
                    <Input type="text" name="street" id="street" value={newAddress?.street} onChange={onAddressChange} />
                  </Col>
                </FormGroup>
                <Row form>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="zip">PLZ</Label>
                      <Input type="text" name="zip" id="zip" value={newAddress?.zip} onChange={onAddressChange} />
                    </FormGroup>  
                  </Col>
                  <Col md={8}>
                    <FormGroup>
                      <Label for="city">City</Label>
                      <Input type="text" name="city" id="city" value={newAddress?.city} onChange={onAddressChange} />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
              <Button color="primary" className="float-right" onClick={saveNewAddress} disabled={!newAddress.company || !newAddress.street || !newAddress.zip || !newAddress.city}>Speichern</Button>
              <br /><br/>
              </>
            :
              <p>
                {address?.company}<br />
                {address?.street}<br />
                {address?.zip} {address?.city}
              </p>
            )}
          <hr />
          {(props.totalMwst - credit) > 0 &&
          <>
            <h6 className="text-secondary flex-grow-1" >Zahlungsmethode</h6>
            <ButtonGroup className="mb-2">
              {paymentOptions.map(pO =>
                getPaymentOptionButton(pO)
              )}          
            </ButtonGroup>
            {selectedPaymentOption === 'card' && <Stripe uid={props.uid} selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} />}
          </>
          }
 
          <Table>
            <tbody>
              <tr>
                <td>Total (exkl. Mwst)</td>
                <td>{props.total.toFixed(2)} CHF</td>
              </tr>
              <tr>
                <td>Total (inkl. Mwst)</td>
                <td>{props.totalMwst?.toFixed(2)} CHF</td>
              </tr>
              {credit !== 0 &&
                <tr>
                  <td>Guthaben</td>
                  <td>{credit.toFixed(2)} CHF</td>
                </tr>
              }
              <tr>
                <th>Zu bezahlen</th>
                <th>{(props.totalMwst - credit) > 0 ? (props.totalMwst - credit).toFixed(2) : "0.00"} CHF</th>
              </tr>
            </tbody>
          </Table>
          <FormGroup check className="float-right">
            <Label check>
              <Input type="checkbox" checked={agbsAccepted} onChange={() => setAgbsAccepted(!agbsAccepted)} />{' '}
              <a href="https://biddad.com/agb/" target="_blank" rel="noopener noreferrer">AGBs</a> akzeptieren
            </Label>
            <br />
            <Button color="primary" onClick={() => btnConfirm()} disabled={!readyToComplete}>Jetzt bestätigen</Button>
          </FormGroup>
        </CardBody>
      </Card>
    </>
  )
}

export default withFirebase(Checkout);