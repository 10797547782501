
import React from 'react';

import { Modal, ModalBody } from 'reactstrap';

const CartErrorModal = (props) => {

  return (
    <Modal isOpen={props.isOpen}>
      <ModalBody>
        <p>Der Warenkort wird gerade fertiggestellt, weshalb diese Buchung nicht zum Warenkorb hinzugefügt werden kann.</p>
        <span className="float-right"><a href="/cart">Zum Warenkorb</a></span>
      </ModalBody>
    </Modal>
  )
}

export default CartErrorModal;