import React, { useState, useEffect } from 'react';
import { withFirebase } from '../Firebase';

import { useLocation } from 'react-router-dom';
import * as ROUTES from '../../_constants/routes';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AutoLogin = (props) => {
  let query = useQuery();

  useEffect(() => {
    // check query strings for autologin
    const user = query.get("user");
    const password = query.get("password");
    if(user && password) {
      const email = user + "@biddad.com";
      props.firebase.doLoginWithEmailAndPassword(email, password)
      .then(() => {props.history.push(ROUTES.SCREENS);})
    } else {
      props.history.push(ROUTES.SCREENS);
    }
  }, [])
  return(
    <></>
  )
}

export default withFirebase(AutoLogin);