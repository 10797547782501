import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';

import { withFirebase } from '../Firebase';

import { Container, Row, Col } from 'reactstrap';

import AccountDetails from './accountDetails';
import Finance from './finance';
import CartsList from './cartsList';
import Media from './media';
import { AuthUserContext, withAuthorization } from '../_auth/Session';

import Footer from '../Footer';
import Loading from '../Loading';

const AccountBase = (props) => {

  const [ userDoc, setUserDoc ] = useState();
  const [ billingAccount, setBillingAccount ] = useState();

  useEffect(() => {
    console.log('account rendered')
    const unsubscribe = props.firebase.db.collection('users').doc(props.uid).onSnapshot(snap => {
      const data = snap.data();
      console.log('once')
      setUserDoc({...data, id: props.uid});
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = props.firebase.db.collection('billingAccounts').doc(props.uid).onSnapshot(snap => {
      const data = snap.data();
      setBillingAccount(data);
    });
    return () => unsubscribe();
  }, []);

  return(
    <>
    {!userDoc ? <Loading /> : 
      <>
        <Container className="pageContent">
          <Row className="pageTitle">
            <Col><h4>Account</h4></Col>
          </Row>
          <AccountDetails user={userDoc} billingAccount={billingAccount}/>
          <Finance user={userDoc} firebase={props.firebase} credit={billingAccount?.credit} />
          <CartsList user={userDoc} firebase={props.firebase} />
          <Media media={userDoc && userDoc.media}/>
        </Container>
      </>
    }
      <br />
      <Footer/>
    </>
  )
}

const condition = authUser => !!authUser;

const AccountPage = compose(
  withAuthorization(condition),
  withFirebase,
)(AccountBase)

export default AccountPage;