import React, { useState, useEffect } from 'react';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import * as rdrLocales from 'react-date-range/dist/locale';

import { GAEvent } from '../GA/tracker';
import Moment from 'react-moment';

import {literals} from '../../_constants/literals'

import { Row, Collapse, Col, FormGroup, Input, InputGroup, InputGroupAddon, Badge, Button } from "reactstrap";

const DateRangePicker = (props) => {
  const [state, setState] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    }
  ]);

  const [open, setOpen] = useState(false);

  const updateRange = (val) => {
    setState(val);
    props.callback(val[0].startDate, val[0].endDate);
    // if(val[0].startDate.toISOString().split('T')[0] !== val[0].endDate.toISOString().split('T')[0]) setOpen(false);
  }

  useEffect(() => {
    setState([{
      startDate: props.start,
      endDate: props.end,
      key: 'selection',
    }])
  }, [props.start, props.end]);

  return (
    <>
      <Row>
        <Col xs="3">
          <Button color="primary" className="ml-3" id="btnCalendar" onClick={() => {GAEvent('Book', 'ShowCalendar'); setOpen(!open)}} outline><i className="fas fa-calendar-alt fa-lg"></i><span> </span></Button>
        </Col>
        <Col xs="6" className="align-self-center">
          <div className="text-center align-middle"><Moment color="primary" format="DD.MM.YYYY">{state[0].startDate}</Moment> - <Moment format="DD.MM.YYYY">{state[0].endDate}</Moment></div>
        </Col>
        <Col xs="3" className="align-self-end">
          <Button color="primary" className="float-right align-middle" onClick={() => {GAEvent('Book', 'SelectAllSlots'); props.selectAllSlots()}} size="sm" outline>{literals.selectSlotAll}</Button>
        </Col>
      </Row>
      <Collapse isOpen={open}>
        <DateRange
          minDate={props.minDate || new Date()}
          editableDateInputs={true}
          onChange={item => updateRange([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={state}
          showDateDisplay={false}
          locale={rdrLocales.de}
        />
      </Collapse>
    </>
  )
}

export default DateRangePicker;

  
