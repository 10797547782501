import React from 'react';
import { ClipLoader } from 'react-spinners';
import { Button, ModalBody, ModalFooter, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Col, FormGroup } from 'reactstrap';

const initialState = {
  password: '',
  email: '',
  error: '',
  loading: false,
}

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
  }

  onSubmit = event => {
    event.preventDefault();
    this.setState({loading: true});

    const { email, password } = this.state;

    this.props.firebase
      .doLoginWithEmailAndPassword(email, password)
      .then(() => {})
      .catch(error => {
        this.setState({ error, loading: false });
      });
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  fbLogin = () => {
    this.props.firebase.fbLogin();
  }

  render() {

    const { email, password, error, loading } = this.state;
    const isInvalid = password === '' || email === '';

    return (
      <div>
        <ModalBody>
          <Form onSubmit={this.onSubmit}>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><i className="fas fa-envelope"></i></InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={this.onChange}
                    autoFocus={true}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><i className="fas fa-lock"></i></InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Passwort"
                    value={password}
                    onChange={this.onChange}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              {loading ? <Button className="btn-block my-2" disabled={true}><ClipLoader sizeUnit={"px"} size={50} color={'#123abc'} loading={loading} /></Button>
                : isInvalid ? <Button className="btn-block my-2" color="secondary" disabled="true">Login</Button> 
                            : <Button className="btn-block my-2" color="primary">Login</Button> }
              {error && <p>{error.message}</p>}
            </Col>
            <Button color="link"  onClick={() => this.props.changeMode("passwordforget")}>Passwort vergessen?</Button>
          </Form>
          </ModalBody>
          <ModalFooter>
            <p className="mr-auto">Noch keinen Account?<Button color="link" onClick={() => this.props.changeMode("signup")}>Jetzt Registrieren</Button></p>
            {/* <br />
            <Button color="primary" onClick={() => this.fbLogin()}>Use Facebook</Button> */}
          </ModalFooter>
      </div>
    )
  }
}

export default Login;