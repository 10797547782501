import React from 'react';

import { Button } from 'reactstrap';

const SelectMedia = () => {

  return (
    <div>
      <center>
      Dort finden Sie nun alle wichtigen Informationen, wie die Dauer eines Slots sowie die Anzahl Aufschaltungen Ihres Inhalts pro Slot. 
      Anschliessend können Sie ein bestehendes Medium auswählen oder mit "Neues Medium" ein neues Medium hochladen.
      <br/>
      <img  src="/GettingStarted/selectMedia.png" className="img-fluid mx-auto d-block my-2" />
      <br/>
      Beim Hochladen eines neuen Mediums wird dieses direkt auf die Auflösung des Screens angepasst und eine Vorschau angezeigt.
      </center>
      
    </div>
  )
}

export default SelectMedia;