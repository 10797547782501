import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';

import { compose } from 'recompose';

import { Button, Badge } from 'reactstrap';

import * as ROUTES from '../../_constants/routes';

const CartBadgeBase = (props) => {

  const [ visible, setVisible ] = useState(false);
  const [ nrPositions, setNrPositions ] = useState(0);

  useEffect(() => {
    const unsubscribe = props.firebase.db.collection('carts').doc(props.uid).onSnapshot(snap => {
      const data = snap.data();
      if(data) {
        setVisible(data.createdAt ? true : false);
        setNrPositions((data.deals?.length || 0) + (data.slots?.length || 0));
      }

    });
    return () => unsubscribe();
  }, []);

  return (
    visible && window.location.pathname !== '/cart' && 
    <div className="position-absolute" style={{top: "100px", right: "10px", zIndex: 1}}>
      <Button color="primary" outline className="btn-circle" onClick={() => {props.history.push(ROUTES.CART);}}>
        <i className="fas fa-shopping-cart fa-lg"></i><Badge className="position-absolute" color="danger">{nrPositions}</Badge>
      </Button>
    </div>
  )
}

const CartBadge = compose(
  withFirebase,
  withRouter,
)(CartBadgeBase);

export default CartBadge;
