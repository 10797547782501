//export const LANDING = '/';
//export const COLORBOARDS = '/colorboards';

export const ROOT = '/';

export const SCREENS = '/overview';
export const AUTOLOGIN = '/screens';
// export const SCREENSMAP = '/screens/map';
// export const SCREENSGALLERY = '/screens/gallery';

export const BOOKSCREEN = '/book/:cid';
export const BOOKDEAL = '/deal/:did';

export const MYBOOKINGS = '/mybookings';

export const MYPROPERTY_SCREENBOOKINGS = '/myproperty/screenbookings'
export const MYPROPERTY = '/myproperty';

export const ACCOUNT = '/account';

export const CARTHISTORY = '/cart/:cid';
export const CART = '/cart';

export const SIGN_UP = '/auth/signup';
export const LOGIN = '/auth/login';
export const PASSWORD_FORGET = '/auth/pw-forget';