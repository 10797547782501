import React, { useState } from 'react';

import { compose } from 'recompose';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Input } from 'reactstrap';


import { useRecoilState } from "recoil";
import { gettingStartedOpenState } from "../_recoil/atoms.js"

import { AuthUserContext, withAuthorization } from '../_auth/Session';
import { withFirebase } from '../Firebase';
import Loading from '../Loading';

import GettingStarted from '../GettingStarted';

import { GAModal, GAEvent } from '../GA/tracker';

const HelpBase = (props) => {

  const [ loading, setLoading ] = useState(false);
  const [ inputValue, setInputValue ] = useState();
  
  const [ gettingStartedOpen, setGettingStartedOpen ] = useRecoilState(gettingStartedOpenState);

  const onChange = (event) => {
    setInputValue(event.target.value);
  }

  const send = async(email) => {
    setLoading(true);
    await props.firebase.sendFeedbackCallable({email, path: window.location.href, message: inputValue});
    setLoading(false);
    props.toggleHelpOpen();
    setInputValue("");
    GAEvent('Help', 'MessageSent');
  }

  return (
    <div>
      <AuthUserContext.Consumer>
        {authUser => (
          authUser && 
        <div style={{position: 'absolute', bottom: 0, right: 0, margin: "10% 5px"}}>
          {/* <Button onClick={() => {GAEvent('Help', 'HelpOpened'); toggle()}} color="danger" outline><i className="fas fa-question fa-2x"></i></Button> */}
          <Modal isOpen={props.isOpen}>
            <Form>
              <ModalHeader toggle={props.toggleHelpOpen}>Hilfe</ModalHeader>
              {loading ? (<Loading />) : (
              <div>
                <ModalBody>
                  <h6>Fragen? Feedback?</h6>
                  <Input 
                    type="textarea"
                    rows="10"
                    name="message"
                    id="message"
                    placeholder="Nachricht"
                    value={inputValue}
                    onChange={onChange}
                  />
                  <Button className="btn-block mt-2" color="primary" onClick={() => send(authUser.email || authUser.uid)}>Senden</Button>
                </ModalBody>
                <ModalFooter>
                  <Button className="mr-auto" color="link" onClick={() => {GAEvent('Help', 'ShowGettingStarted'); setGettingStartedOpen(true); props.toggleHelpOpen()}}>Anleitung</Button>
                  <a href="https://biddad.com/faq/" target="_blank" rel="noopener noreferrer">Häufig gestellte Fragen</a>
                  
                </ModalFooter>
              </div>
              )}
            </Form>
          </Modal>
          <GettingStarted />
        </div>
        )}
      </AuthUserContext.Consumer>
    </div>
  )
}

// const condition = authUser => !!authUser;

const Help = compose(
  // withAuthorization(condition),
  withFirebase,
)(HelpBase)

export default  Help;