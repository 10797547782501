import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';

import {  Button, Row, Col, Card, CardImg, Carousel, CarouselItem, CardBody, CardFooter, CardTitle } from 'reactstrap';
import { GAEvent } from '../GA/tracker';

import Loading from '../Loading';

const DealCardBase = (props) => {

  const [ loading, setLoading ] = useState(true);
  const [ thumbnailURL, setThumbnailURL ] = useState('');

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const fetchThumbnail = async() => {
    setLoading(true);
    if(props.deal.thumbnails && props.deal.thumbnails.length > 0) {
      let mediaData = await props.firebase.loadMediaDirect(props.deal.thumbnails);
      setThumbnailURL(mediaData);
    } else if(props.deal.thumbnail) {
      let mediaData = await props.firebase.loadMedia([props.deal.thumbnail]);
      if(mediaData[0] && mediaData[0].url) setThumbnailURL(mediaData[0].url);
    }
    setLoading(false);
    //let mediaData = await this.props.firebase.loadMedia(this.props.images);
  }

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === thumbnailURL.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? thumbnailURL.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const getSlides = () => {
    const slides = thumbnailURL.map((m) => {
      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={m.url}
        >
          <img src={m.url} alt={'none'} className="img-fluid" width="100%"/>
        </CarouselItem>

      )
    })
    return slides;
  }

  useEffect(() => {
    fetchThumbnail();
  }, [])


  return (
      <Card className="my-2 shadow">
        {loading ? <Loading /> : <Link to={"/deal/" + props.deal.id}>
        {props.deal.thumbnails && props.deal.thumbnails.length > 0 ?
          <>
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              ride={"carousel"}
              interval={4000}
            >
              {getSlides()}
              {/* <div style={{position: "absolute", bottom: "0px", background: "#49c5b6", color: "white", margin: "2px", padding: "2px"}}><small>3 verfügbar</small></div> */}
            </Carousel>
          </>        
          : props.deal.thumbnail && <CardImg top width="100%" src={thumbnailURL} alt={props.deal.name} /> }
        </Link>}
        
        <CardBody className="pb-0">
          <CardTitle><h5>{props.deal.name}</h5></CardTitle>
          <p>{props.deal.description}</p>
          {/* <img className="float-right" src="/logo-kanawai-h-20px.png" /> */}   
          <div><strike>{props.deal.price.toFixed(2) + ' CHF'}</strike> {props.deal.priceDiscount.toFixed(2) + ' CHF'}</div>       
          {props.deal.network && props.deal.network.logo && <div className="float-right"><img src={props.deal.network.logo} /></div>}
        </CardBody>
        <hr />
        <CardBody className="pt-0">
          <Row>
            <Col sm="7" className="align-self-center" >
              <i className="fas fa-clock"></i> {props.deal.durationDays/7} Wochen<br/>
              <i className="fas fa-portrait"></i> {props.deal.colorboards.length} Screens
            </Col>
            <Col sm="5">
              <div className="float-right">
                <Link to={"/deal/" + props.deal.id}>
                  <Button color="primary">Deal</Button>
                </Link>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
  )
}

const DealCard = compose(
  withFirebase,
)(DealCardBase)

export default DealCard;