import React, { useState, useEffect } from 'react';

import {  Badge, Row, Col, Table, Button  } from 'reactstrap';

import moment from 'moment';

import Loading from '../Loading';

const MyDeals = (props) => {

  const [ dealBookings, setDealBookings ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  const [ mode, setMode ] = useState()

  const getDealsPublisher = async() => {
    const dealClaims = props.authUser && props.authUser.claims && props.authUser.claims.publisherDeals;
    if(dealClaims && dealClaims.length > 0) {
      setLoading(true);

      let dBookings = []

      await Promise.all(dealClaims.map(async(dC) => {
        const dRef = props.firebase.db.collection('deals').doc(dC);
        const dData = (await dRef.get()).data();
        if(dRef && dData) {
          const cbs = [];
          await Promise.all(dData.colorboards && dData.colorboards.length > 0 && dData.colorboards.map(async(cbRef) => {
            // TODO this needs to be optimized at some point: fetching colorboards multiple times
            const cb = (await cbRef.get()).data();
            cbs.push({id: cbRef.id, address: cb.address});
          }))
          const dBRefs = await dRef.collection('dealBookings').get();
          dBRefs.forEach(dB => {
            dBookings.push({id: dB.id, dealId: dRef.id, dealName: dData.name, cbs, ...dB.data()})
          })
        }
      }))

      const uniqMedia = [...new Set(dBookings.map(item => item.media))];
      const uniqUsers = [...new Set(dBookings.map(item => item.user))];

      const media = {};
      await Promise.all(uniqMedia.map(async(m)=> {
        const mData = (await props.firebase.db.doc(m).get()).data();
        const downloadLink = await props.firebase.storageRef().child(mData.fullPath).getDownloadURL();
        media[m] = {displayname: mData.displayname, downloadLink }
      }))

      const users = {};
      await Promise.all(uniqUsers.map(async(u)=> {
        const uData = (await props.firebase.db.doc(u).get()).data();
        users[u] = {email: uData?.email};
      }))

      dBookings = dBookings.map(b => {
        const booking = b;
        booking.media = media[b.media];
        booking.user = users[b.user];
        booking.visible = true;
        booking.selected = false;
        return booking;
      })

      setDealBookings(dBookings);
      setLoading(false);
    }
  };

  const confirmMode = () => {
    setMode('confirm');
    let bookings = dealBookings;
    bookings.map(b => {
      b.selected = false;
      if(new Date(b.slots[0].start.toDate()) > new Date() && b.status === 'pending') {
        b.visible = true;
      } else {
        b.visible = false;
      }
    })
    setDealBookings([...bookings])
  }

  const declineMode = () => {
    setMode('decline');
    let bookings = dealBookings;
    bookings.map(b => {
      b.selected = false;
      if(new Date(b.slots[0].start.toDate()) > new Date() && b.status !== 'declined') {
        b.visible = true;
      } else {
        b.visible = false;
      }
    })
    setDealBookings([...bookings])
  }

  const resetMode = () => {
    let bookings = dealBookings;
    bookings.map(b => {
      b.selected = false;
      b.visible = true;
    })
    setDealBookings([...bookings]);
    setMode();
  }

  const checkSingle = (index) => {
    let bookings = dealBookings;
    bookings[index].selected = !bookings[index].selected;
    setDealBookings([...bookings]);
  }

  const confirm = async() => {
    setLoading(true);
    let bookings = {}
    dealBookings.map(b => {
      if(b.selected === true) {
        bookings[b.dealId] ? bookings[b.dealId].push(b.id) : bookings[b.dealId] = [b.id];
      }
    })
    for(let b in bookings) {
      await props.firebase.confirmDealCallable({did: b, bids: bookings[b]});
    }
    setMode();
    getDealsPublisher();
  }

  const decline = async() => {
    setLoading(true);
    let bookings = {}
    dealBookings.map(b => {
      if(b.selected === true) {
        bookings[b.dealId] ? bookings[b.dealId].push(b.id) : bookings[b.dealId] = [b.id];
      }
    })
    for(let b in bookings) {
      await props.firebase.declineDealCallable({did: b, bids: bookings[b]});
    }
    setMode();
    getDealsPublisher();
  }

  useEffect(() => {
    getDealsPublisher();
  }, [])


  return(
    loading ? <Loading /> : 
    dealBookings.length > 0 &&
      <>
        <Row>
          <Col>
            <h5 className="pageTitle">Publisher - Deals</h5>
          </Col>
          <Col>
            <div className="float-right">
              {mode == 'confirm' ?
                <span><Button color="success" onClick={() => confirm()}>Confirm selected</Button><Button outline color="secondary" className="float-right" onClick={resetMode}>X</Button></span>
              : mode == 'decline' ? 
                <span><Button color="danger" onClick={() => decline()}>Decline selected</Button><Button outline color="secondary" className="float-right" onClick={resetMode}>X</Button></span>
              :
                <span>
                  <Button color="success" outline onClick={() => confirmMode()}>confirm</Button><span> </span><Button color="danger" outline onClick={() => declineMode()}>decline</Button>
                </span>}
            </div>

          </Col>
        </Row>
        <Table>
          <thead>
            <tr>
              {mode && <th></th>}
              <th>Status</th>
              <th>Deal</th>
              <th>Screens</th>
              <th>Start</th>
              <th>Ende</th>
              <th>Media</th>
              <th>user</th>
              <th>Preis</th>
            </tr>
          </thead>
          <tbody>
            {
              dealBookings.map((booking, index) => (booking.visible && 
                <tr key={booking.id}>
                  {mode && <td><input type="checkbox" checked={booking.selected} onChange={() => checkSingle(index)}/></td>}
                  <td>{
                    booking.status === 'confirmed' ? (
                      <Badge color="success">bestätigt</Badge>
                    ) : (
                      booking.status === 'pending' ? (
                        <Badge color="warning">ausstehend</Badge>
                      ) : (
                        <Badge color="danger">abgelehnt</Badge>
                      )
                    )
                  }</td>
                  <td><a href={"/deal/" + booking.dealId} target="_blank" rel="noopener noreferrer">{booking.dealName}</a></td>
                  <td>
                    {booking.cbs.map(cb => 
                      <span key={cb.id}><a href={"/book/" + cb.cbid} target="_blank" rel="noopener noreferrer">{cb.address}</a><br /></span>
                    )}
                  </td>
                  <td>
                    {booking.slots.map((slot, index) => 
                      <span key={index}>{String(moment(slot.start.toDate()).format('DD.MM.YYYY'))}<br /></span>
                    )}
                  </td>
                  <td>
                    {booking.slots.map((slot, index) => 
                      <span key={index}>{String(moment(slot.end.toDate()).format('DD.MM.YYYY'))}<br /></span>
                    )}
                  </td>
                  <td><a href={booking.media.downloadLink} target="_blank" rel="noopener noreferrer">{booking.media.displayname}</a></td>
                  <td>{booking.user.email}</td>
                  <td>{booking.status === 'declined' ? (<del>{booking.price.toFixed(2) + ' CHF'}</del>):(booking.price.toFixed(2) + ' CHF')}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </>
  )
}

export default MyDeals;