import React from 'react';

import { Button } from 'reactstrap';

const Screens = () => {

  return (
    <div>
      <center>
      Klicken Sie in der Navigationsleiste auf "Screens", um die verfügbaren Screens anzuzeigen.<br/>
      <img  src="/GettingStarted/screenGallery.png" className="img-fluid mx-auto d-block my-2" /> <br/>
      Mit "Buchen" erhalten Sie mehr Informationen zum entsprechenden Screen und können den Buchungsprozess starten.
      </center>
      
    </div>
  )
}

export default Screens;