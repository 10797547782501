import React from 'react';

import {  Container, Col, Row } from 'reactstrap';

import { GAEvent } from '../GA/tracker';

import ScreenCard from './ScreenCard';

const Gallery = (props) => {

  return (
    <Row>
      {props.colorboards.map(cb => 
        <Col key={cb.id} lg="4" sm="6"><ScreenCard cb={cb} key={cb.id} /></Col>
      )}
    </Row>
  )
}

export default Gallery;