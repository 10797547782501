import React, { useState, useEffect, useRef } from 'react';

import { Collapse, Input, InputGroup, InputGroupAddon, Row, Col, Table, Tooltip, FormGroup, Button, Alert, Card, CardBody } from "reactstrap";

import {Elements, CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import Loading from '../Loading';
import { withFirebase } from '../Firebase';

const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#49c5b6",
      fontSize: "16px",
      fontSmoothing: "antialiased",
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238"
      }
    }
  }
};

const StripeForm = (props) => {

  const [ loading, setLoading ] = useState(false);

  const [ err, setErr ] = useState(false);
  const [ addCardErr, setAddCardErr ] = useState(false);

  const [ stripeCustomer, setStripeCustomer ] = useState();

  const [ paymentMethods, setPaymentMethods ] = useState([]);
  // const [ selectedPaymentMethod, setSelectedPaymentMethod ] = useState('empty');

  const [ addCardOpen, setAddCardOpen ] = useState(false);
  // const [ selectedCard, setSelectedCard ] = useState();

  const stripe = useStripe();
  const elements = useElements();

  const getStripeCustomer = async() => {
    setLoading(true);
    const scust = (await props.firebase.db.collection('billingAccounts').doc(props.uid).get()).data();
    if(scust) setStripeCustomer(scust);
    setLoading(false);
  }

  const addCard = async() => {
    const cardElem = elements.getElement(CardElement)
    setLoading(true);
    let cardholderName = '';
    const userDoc = (await props.firebase.db.collection('users').doc(props.uid).get()).data();
    if(userDoc) cardholderName = userDoc.firstname + ' ' + userDoc.lastname;

    const { setupIntent, error } = await stripe.confirmCardSetup(
      stripeCustomer.stripe_setup_secret,
      {
        payment_method: {
          card: cardElem
        },
      }
    );
    setAddCardErr(error);
    error && setLoading(false);
    if (!error) {
      await props.firebase.db.collection('billingAccounts').doc(props.uid).collection('paymentMethods').add({id: setupIntent.payment_method, provider: 'stripe'});
      setAddCardOpen(false);
    }
    cardElem.clear();
  }

  const listenToPaymentMethodsChange = async() => {
    
    props.firebase.db.collection('billingAccounts').doc(props.uid).collection('paymentMethods').onSnapshot((snap) => {
      let pMethods = [];
      if(snap.empty) {
        setAddCardOpen(true);
      }
      snap.forEach((doc) => {
        const paymentMethod = doc.data();
        if (paymentMethod.error) {
          return;
        }
        if (paymentMethod.card) {
          pMethods.push({id: doc.id, pmid: paymentMethod.id,
                          cardNr: "**** **** **** " + paymentMethod.card?.last4,
                          cardExp: paymentMethod.card.exp_month?.toLocaleString(undefined, {minimumIntegerDigits: 2}) + "/" + paymentMethod.card.exp_year?.toString(),
                          brand: paymentMethod.card.brand,
                        })
        } else {
          pMethods.push({id: doc.id, pmid: paymentMethod.id, loading: true});
        }
      })
      !props.selectedPaymentMethod && !pMethods[0]?.loading && props.setSelectedPaymentMethod(pMethods[0]?.pmid);
      setPaymentMethods(pMethods); 
      setLoading(false);
    })
  }

  useEffect(() => {
    getStripeCustomer();
    listenToPaymentMethodsChange();
  }, []);

  return (
    (err ? <p>err</p> :
      <div>
        {paymentMethods.map(pM =>
          pM.loading ? <Loading /> :
          <Button color="primary" key={pM.id} className="my-2 py-3 text-left" block outline onClick={() => props.setSelectedPaymentMethod(pM.pmid)} >
            <span>{pM.brand}, {pM.cardNr}, {pM.cardExp} {props.selectedPaymentMethod === pM.pmid && <span className="float-right"><i className="fas fa-check"></i></span>}</span>
          </Button>
        )}
        <Card className="my-2">
          <CardBody>
            <div className="d-flex">
              <h5 className="text-secondary flex-grow-1" onClick={() => setAddCardOpen(!addCardOpen)}>Neue Karte</h5>
              <h5 className="float-right"><Button onClick={() => setAddCardOpen(!addCardOpen)} color="link"><i className={addCardOpen ? "fas fa-minus text-primary" : "fas fa-plus text-primary"} /></Button></h5>
            </div>
            <Collapse isOpen={addCardOpen}>
              <div className="p-4 border"><CardElement options={CARD_ELEMENT_OPTIONS} /></div>
              <br />
              {addCardErr && <span>{addCardErr.message}</span>}
              {loading || !stripe || !elements ? <Loading /> : <Button color="primary" className="float-right" onClick={addCard}>Speichern</Button>}
            </Collapse>
          </CardBody>
        </Card>
      </div>
    )


  )
}

// DEV
// const stripePromise = loadStripe('pk_test_51Ht8ciAMQb22njiXlQXfInz88wZcRg1daQEPxQjAirq68OyiIKuEQXU9I9UXZLfNuuJBugzYLhgF8TrzscSPdGl500SbC9cU6x');

// PROD
const stripePromise = loadStripe('pk_live_51Ht8ciAMQb22njiXZFy0uETb6VCFA2bG1Wt6pWvtHubTsTojm5yNy2RyWsw00fKXF8hmaoE2XPWSn6ijoybjJZYa00fXewxb25');

const Stripe = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <StripeForm {...props} />
    </Elements>
  )
}

export default withFirebase(Stripe);