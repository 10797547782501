import React from 'react';

import { Card, CardBody, Table } from 'reactstrap';

const Details = (props) => {

  return(
    <Card className="my-2 shadow">  
      <CardBody>
        <div className="d-flex">
          <h5 className="flex-grow-1"><i className="fas fa-user"></i> Infos</h5>
        </div>  
        <div className="mt-2">
          <Table>
            <tbody>
              <tr>
                <th>Email</th><td>{props.user?.email}</td>
              </tr>
              <tr>
                <th>Vorname</th><td>{props.user?.firstname}</td>
              </tr>
              <tr>
                <th>Nachname</th><td>{props.user?.lastname}</td>
              </tr>
              <tr>
                <th>Rechnungsadresse</th>
                <td>
                  {props.billingAccount?.company}<br />
                  {props.billingAccount?.street}<br />
                  {props.billingAccount?.zip} {props.billingAccount?.city}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>   
  )
}

export default Details;