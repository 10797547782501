import React, { useState, useEffect } from 'react';


import { Container, Row, Col, Table, Tooltip, Button, Collapse, Card, CardBody } from "reactstrap";

const Infos = (props) => {

  return(
    <Row>
      
      {props.infos.map(i => (
        <Col sm="6">
          <Row className>
            <Col xs="2">
              <span style={{color: "#49c5b6"}}><i className={i.faIcon}></i></span>
            </Col>
            <Col xs="10">
              <small><b>{i.title}</b><br />
              <div dangerouslySetInnerHTML={{ __html: i.text }} /></small><br />
            </Col>
          </Row>
        </Col>
      ))}

    </Row>
  )
}

export default Infos;

