import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import moment from 'moment';
import { compose } from 'recompose';

import { Container, Row, Col, Table, Tooltip, Button, Card, CardBody, } from "reactstrap";

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../_auth/Session';

import {literals} from '../../_constants/literals'

import CartErrorModal from '../Cart/cartErrorModal';
import CartAddedModal from '../Cart/cartAddedModal';

import Photos from './photos';
import Loading from '../Loading';
import MediaSelector from './MediaSelector';
import SelectSlots from './SelectSlots';
import Infos from './infos';

import * as ROUTES from '../../_constants/routes';

import { GAEvent } from '../GA/tracker';

// booked: false
// id: "kkFuM7yRaAUXctE0D7uc"
// price: 15
// start: "2020-07-04T12:00:00.000

const durationToString = (duration) => {
  let out = '';
  let hours = Math.floor(duration / 3600);
  let minutes = Math.floor(duration%3600/60);
  let seconds = Math.floor(duration%60);

  if (hours) { 
    if (hours > 1) {
      out += String(hours) + ' ' + literals.hours + ' '; 
    } else {
      out += String(hours) + ' ' + literals.hour + ' '; ; 
    }
  }
  if (minutes) {
    if (minutes > 1) {
      out += String(minutes) + ' ' + literals.minutes + ' '; 
    } else {
      out += String(minutes) + ' ' + literals.minute + ' '; ; 
    }
  }
  if (seconds) {
    if (seconds > 1) {
      out += String(seconds) + ' ' + literals.seconds; 
    } else {
      out += String(seconds) + ' ' + literals.second;
    }
  }
  return out;
}

const BookBase = (props) => {

  // const [open, setOpen] = useState(false);
  
  const [loading, setLoading ] = useState(true);
  const [loadingSlots, setLoadingSlots ] = useState(true);

  const [user, setUser] = useState();

  const [colorboard, setColorboard ] = useState()
  const [ selectedMedia, setSelectedMedia ] = useState();
  const [slots, setSlots] = useState([]);

  const [ cartError, setCartError ] = useState(false);
  const [ cartSuccess, setCartSuccess ] = useState(false);

  const [total, setTotal] = useState(0);
  const [totalDiscount, setTotalDiscount ] = useState(0); // total with discount

  const [dateRange, setDateRange] = useState({});  // date range set by Filter

  const [ toolTipScreenTime, setToolTipScreenTime ] = useState(false);
  const [ toolTipBook, setToolTipBook ] = useState(false);

  const loadFirebaseData = async() => {
    await props.firebase.colorboard(props.match.params.cid).get().then(async(snap) => {
      let colorboard = snap.data()
      if(colorboard && colorboard.datasheet) {
        try {
          const downloadLink = await props.firebase.storageRef().child(colorboard.datasheet).getDownloadURL();
          colorboard = {...colorboard, datasheet: downloadLink}
        } catch (err) {
          console.log('error fetching datasheet pdf')
        }
      }
      setColorboard(colorboard)
    });
    // fetch full user document
    await props.firebase.user(props.authUser.uid).get().then(user => {
      let data = user.data();
      setUser(data)
    })
    setLoading(false);

    // set initial date range filter
    let end = new Date();
    end.setDate(end.getDate()+7);
    updateDateRange(new Date(), end);
  }

  const getSlots = async() => {
    if(dateRange && dateRange.start && dateRange.end && colorboard) {

      let fetchedSlots = [];
      setLoadingSlots(true);
  
      const slotRefs = await props.firebase.db.collection('colorboards').doc(props.match.params.cid).collection('slots').where('start', '>=', dateRange.start).where('start', '<', dateRange.end).get();
  
      let leadTime= new Date();
      leadTime.setSeconds(leadTime.getSeconds() + colorboard.leadTime || 0);
      slotRefs.forEach(sR => {
        const slotData = sR.data();
        let overlapB = colorboard.overlappingBookings || 0;
        if(overlapB >= slotData.booked && new Date(slotData.start.toDate()) >= leadTime) {
          fetchedSlots.push({id: sR.id, start: slotData.start.toDate(), end: slotData.end.toDate(), price: slotData.price, visible: true, selected: false});
        }
      })

      // add already selected slots
      let newSlots = slots.filter(s => {
        if(s.selected) {
          s.visible = false;
          return s;
        }
      })

      //merge exisiting and fetched slots
      fetchedSlots.map(fS => {
        console.log()
        const existingIndex = newSlots.findIndex(obj => obj.id === fS.id);
        if(existingIndex !== -1) {
          // slot already fetched, keep "selected" but update "visible"
          newSlots[existingIndex].visible = true;
        } else {
          // new slot
          newSlots.push(fS);
        }
      })

      newSlots = newSlots.sort((a,b) => a.start > b.start ? 1 : a.start < b.start ? -1 : 0);

      setSlots(newSlots);
      setLoadingSlots(false);
    }

  }

  const updateDateRange = async(start, end) => {

    let startFilter = new Date(start);
    startFilter.setHours(0,0,0,0);

    let endFilter = new Date(end);
    endFilter.setHours(23,59,59,999);

    setDateRange({start: startFilter, end: endFilter})
  }

  const toggleSlot = (tStamp) => {
    let newSlots = slots;
    const sIndex = newSlots.findIndex(obj => obj.start == tStamp);
    newSlots[sIndex].selected = !newSlots[sIndex].selected;
    if(newSlots[sIndex].selected) {
      GAEvent('Book', 'SlotSelected')
    } else {
      GAEvent('Book', 'SlotDeselected')
    }
    setSlots([...newSlots]);
    calculateTotal();
  }

  const selectMultipleSlots = (tStamps) => {
    let newSlots = slots;
    tStamps.map(start => {
      const sIndex = newSlots.findIndex(obj => obj.start == start);
      newSlots[sIndex].selected = true;
    })
    setSlots([...newSlots])
    calculateTotal();
  }

  const selectAllSlots = () => {
    let newSlots = slots;
    slots.map(s => {
      if(s.visible && !s.booked) s.selected = true;
      return s;
    })
    setSlots([...newSlots])
    GAEvent('Book', 'SelectAllSlots')
    calculateTotal();
  }

  const calculateTotal = () => {
    let t = 0;
    let tDiscount = 0;

    slots.map(s => {
      if(s.selected) t += s.price;
    })
    // calculate discounts per day
    if(colorboard?.discount?.day?.slots && colorboard?.discount?.day?.discount ) {

      const selectedSlotsPerDay = new Map();
      // bundle selected slots per day
      for(let s of slots) {
        if(s.selected) {
          const day = String(moment(s.start).format('DD.MM.YYYY'));
          if(selectedSlotsPerDay.has(day)) {
            const newValue = [...selectedSlotsPerDay.get(day)];
            newValue.push(s);
            selectedSlotsPerDay.set(day, newValue)
          } else {
            selectedSlotsPerDay.set(day, [s])
          }
        }
      }
      selectedSlotsPerDay.forEach((value, key) => {
        if(value.length >= colorboard.discount.day.slots) {
          // discount
          value.forEach(s => {
            tDiscount += s.price * (1-colorboard.discount.day.discount);
          })
        } else {
          value.forEach(s => {
            tDiscount += s.price;
          })
        }
      })
    } else {
      tDiscount = t;
    }
    setTotal(t);
    setTotalDiscount(tDiscount);
  }

  const addToCart = async() => {
    setLoading(true);
    const selectedSlots = [];
    slots.map(s => {
      s.selected && selectedSlots.push(props.firebase.db.collection('colorboards').doc(props.match.params.cid).collection('slots').doc(s.id));
    });
    const cartRef = props.firebase.db.collection('carts').doc(props.authUser.uid);
    const cartDoc = await cartRef.get();
    const cartData = cartDoc.data();
    const cbRef = props.firebase.db.collection('colorboards').doc(props.match.params.cid);
    if(cartDoc.exists && cartData.status) {
      setCartError(true);
      setLoading(false);
    } else {
      if(cartDoc.exists && cartData.createdAt) {
        // user has an existing cart
        await cartRef.update({
          slots: props.firebase.FieldValue.arrayUnion({
            media: selectedMedia,
            colorboard: cbRef,
            refs: selectedSlots,
            total,
            totalDiscount,
            addedAt: new Date(),
          }),
        });
      } else {
        // use has no cart or an empty cart
        await cartRef.set({createdAt: new Date(), 
          slots: [{
            media: selectedMedia,
            colorboard: cbRef,
            refs: selectedSlots,
            total,
            totalDiscount,
            addedAt: new Date(),
          }]
        });
      }
      //props.history.push(ROUTES.CART);
      setCartSuccess(true);
      setLoading(false);
      
    }
  }

  useEffect(() => {
    getSlots();
  }, [dateRange]);

  useEffect(() => {
    loadFirebaseData();
  }, []);
  
  return (
    <>
      {loading ? (<Loading />) : (
      <>
      <Container>
      <h4 className="mt-3">{colorboard.address} {colorboard.geolocation && <a href={"https://www.google.com/maps/place/" + colorboard.geolocation.latitude + "," + colorboard.geolocation.longitude} target="_blank" rel="noopener noreferrer"><i className="fas fa-map-marker-alt"></i></a>}</h4>
        <Card className="my-2 shadow">
          <CardBody>
            <div className="d-flex">
              <h5 className="text-primary flex-grow-1"><i className="fas fa-info"></i> {literals.informationTitle}</h5>
              <h5 className="text-primary float-right"><i className="fas fa-check"></i></h5>
            </div>    
            {/* <Collapse isOpen={collapseInfo} className="mt-1"> */}
              <Row className="mt-1">
                <Col md="12" lg="6" className="my-2">
                  <Photos colorboard={colorboard} />
                </Col>
                <Col md="12" lg="6" className="my-2">
                  {colorboard.infos ? <Infos infos={colorboard.infos} /> : 
                  <>
                    {colorboard.description && <p>{colorboard.description}</p>}
                    <Table>
                      <tbody>
                        <tr>
                          <td>{literals.informationSlotDuration}</td>
                          <td className="text-secondary">{durationToString(colorboard.stepSize)}</td>
                        </tr>
                        {colorboard.interval ? 
                        <tr>
                          <td>{literals.informationScreenTime + " "}</td>
                          {/* <td>{literals.informationScreenTime + " "}<span id="ttScreenTime"><i className="fas fa-info-circle text-primary"></i></span></td> */}
                        <td className="text-secondary"><b>min. {colorboard.stepSize/colorboard.interval.rotation}x {literals.each} {durationToString(colorboard.interval.playtime)}</b></td>
                        </tr>
                        :
                        <tr>
                          <td>{literals.informationScreenTime}</td>
                          <td className="text-secondary"><b>{durationToString(colorboard.stepSize)}</b></td>
                        </tr>}
                        {colorboard.performance && 
                        <tr>
                          <td>{literals.performance}</td>
                          <td className="text-secondary">{colorboard.performance.value &&  colorboard.performance.value.toLocaleString()} {colorboard.performance.text && " " + colorboard.performance.text}</td>
                        </tr>
                        }
                        {colorboard.datasheet &&
                        <tr>
                          <td>{literals.informationDataSheet}</td>
                          <td className="text-secondary"><a href={colorboard.datasheet} target="_blank" rel="noopener noreferrer"><i className="fas fa-file-pdf"></i> {colorboard.address}</a></td>
                        </tr>}
                      </tbody>
                    </Table>
                    {/* {colorboard.interval && 
                    <Tooltip placement="auto" isOpen={toolTipScreenTime} target="ttScreenTime" toggle={() => setToolTipScreenTime(!toolTipScreenTime)}>
                        Anbieter offeriert mindestens {colorboard.stepSize/colorboard.interval.rotation} Aufschaltungen {literals.each} {durationToString(colorboard.interval.playtime)} pro Slot. Über {durationToString(colorboard.stepSize)} ergeben sich daraus mindestens {durationToString(colorboard.interval.playtime*colorboard.stepSize/colorboard.interval.rotation)} Anzeige-Zeit.
                    </Tooltip> }*/}
                    
                  </>
                  } 
                </Col>
              </Row>
            {/* </Collapse> */}
          </CardBody>
        </Card>
        <Card className="my-2 shadow">  
          <CardBody>
            <div className="d-flex">
              <h5 className={selectedMedia ? "text-primary flex-grow-1" : "text-secondary flex-grow-1"}><i className="fas fa-photo-video"></i> {literals.selectMediaTitle}</h5>
              <h5 className="float-right">{selectedMedia && <i className="fas fa-check text-primary"/>}</h5>
            </div>  
            {/* <Collapse isOpen={collapseMedia} className="mt-1"> */}
              <div className="mt-2">
                <MediaSelector selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia} user={user} template={colorboard.template} screenDimensions={{x: colorboard.resolutionX, y: colorboard.resolutionY}} restrictions={colorboard.restrictions}/>
              </div>
            {/* </Collapse> */}
          </CardBody>
        </Card>      
        <Card className="my-2 shadow">  
          <CardBody>
            <div className="d-flex">
              <h5 className={slots.findIndex(obj => obj.selected) !== -1 ? "text-primary flex-grow-1": "text-secondary flex-grow-1"}><i className="fas fa-calendar-alt"></i> {literals.selectSlotTitle}</h5>
              <h5 className="float-right">{slots.findIndex(obj => obj.selected) !== -1 && <i className="fas fa-check text-primary"/>}</h5>
            </div>  
            {/* {loadingSlots ? <Loading /> : 
            <div> */}
              {/* {colorboard.discount?.day && 
                <Alert color="secondary" >
                  Wählen Sie {colorboard?.discount?.day?.slots} Slots am gleichen Tag und erhalten Sie {(100*colorboard?.discount?.day?.discount)}% Rabatt.
                </Alert>
              } */}
              <SelectSlots 
                discount={colorboard?.discount}
                dateRange={dateRange}
                updateDateRange={updateDateRange}
                slots={slots}
                toggleSlot={toggleSlot} 
                selectAllSlots={selectAllSlots}
                selectMultipleSlots={selectMultipleSlots}
                loadingSlots={loadingSlots}
              /> 
            {/* </div>} */}
          </CardBody>
        </Card>
        {/* <Confirm uid={props.authUser.uid} selectedMedia={selectedMedia} isOpen={bookModalOpen} toggle={() => setBookModalOpen(!bookModalOpen)} slots={slots} cid={props.match.params.cid} total={total} totalDiscount={totalDiscount} />   */}
        {/* <Confirm uid={props.authUser.uid}/> */}
      </Container>
      <br /><br /><br />
      <Row className="fixed-bottom bg-light p-3">
        <Col className="align-self-center">
            <span className="align-middle">Total: {total !== totalDiscount ? <><strike>{total.toFixed(2)}</strike> {totalDiscount.toFixed(2)} CHF</> : <>{total.toFixed(2)} CHF</>}</span> 
        </Col>
        <Col className="align-self-center d-none d-md-block" md="auto">
          <span>
            <i className="fas fa-lg fa-info text-primary"/>
            <svg height="3" width="150"><line x1="5" y1="0" x2="140" y2="0" stroke={selectedMedia ? "#49c5b6" : "#8c979e"} strokeWidth="3"></line></svg>
            <i className={selectedMedia ? "fas fa-lg fa-photo-video text-primary" : "fas fa-lg fa-photo-video text-secondary"}/>
            <svg height="3" width="150"><line x1="5" y1="0" x2="140" y2="0" stroke={slots.findIndex(obj => obj.selected) !== -1 ? "#49c5b6" : "#8c979e"} strokeWidth="3"></line></svg>
            <i className={slots.findIndex(obj => obj.selected) !== -1 ? "fas fa-lg fa-calendar-alt text-primary" : "fas fa-lg fa-calendar-alt text-secondary"} />
          </span>
        </Col>
        <Col className="align-self-end">
          {slots.findIndex(obj => obj.selected) !== -1 && selectedMedia ? (
            <Button block color="primary" onClick={() => {GAEvent('Book', 'AddToCart'); addToCart()}}><i className="fas fa-shopping-cart"></i> {literals.addToCart}</Button>
          ) : (
            <div className="float-right">
              <Button id="ttBook" color="secondary" onClick={() => GAEvent('Book', 'DisabledShowConfirmBookings')}><i className="fas fa-shopping-cart"></i> {literals.addToCart}</Button>
              <Tooltip placement="top" isOpen={toolTipBook} target="ttBook" toggle={() => setToolTipBook(!toolTipBook)}>
                Um die Buchung abzuschliessen, müssen Sie ein Medium und mindestens einen Slot auswählen.
              </Tooltip>
            </div>
          )}
        </Col>
      </Row>
      <CartErrorModal isOpen={cartError} />
      <CartAddedModal isOpen={cartSuccess} />
      </>
      )}
    </>
  )
}

const condition = authUser => !!authUser;

const Book = compose(
  withAuthorization(condition),
  withFirebase,
  withRouter,
)(BookBase)


export default Book;

  
