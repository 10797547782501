import React from 'react';

import { Button } from 'reactstrap';

const Help = () => {

  return (
    <div>
      <center>
      Falls bei der Benützung der BiddAd Plattform Probleme auftreten, finden Sie bei auf jeder Seite folgenden Button:
      <img  src="/GettingStarted/helpIcon.png" className="img-fluid mx-auto d-block my-2" width="60%"/>
      <br/><br/>
      Sobald Sie diesen betätigen, öffnet sich ein Feld, in das Sie eine Nachricht eingeben können.<br/>
      Diese Nachricht wird direkt an die Betreiber der Plattform weitergeleitet und Sie erhalten so schnell wie möglich Unterstützung.
      <img  src="/GettingStarted/help.png" className="img-fluid mx-auto d-block my-2" width="60%"/>
      Ausserdem haben Sie die Möglichkeit, diese Anleitung nochmals anzuzeigen oder die <a href="https://biddad.com/faq/" target="_blank" rel="noopener noreferrer">häufig gestellten Fragen</a> einzusehen.
      </center>
      
    </div>
  )
}

export default Help;