import React, { useState, useEffect } from 'react';

import { Row, Col } from 'reactstrap';

const Footer = (props) => {


  useEffect(() => {
    
  }, [])

  return (
    <Row className="fixed-bottom py-1 px-2 bg-light">
      <Col className="align-self-center" xs="4">
        <small><a href="mailto:feedback@biddad.com" className="footerLink">feedback@biddad.com</a></small>  
      </Col>
      <Col className="align-self-center" xs="4">
        <div className="text-center"><small><a className="footerLink" href="https://biddad.com/agb/" target="_blank" rel="noopener noreferrer">AGBs</a></small></div>
      </Col>
      <Col className="align-self-end" xs="4">
        <small><a className="float-right footerLink" href="https://www.biddad.com/" target="_blank" rel="noopener noreferrer">www.biddad.com</a></small>
      </Col>
    </Row>
  );
};

export default Footer;