import React, { useState, useEffect } from 'react';

import { Button, Card, CardBody, Table, Row, Col, Badge, Tooltip } from 'reactstrap';

import moment from 'moment';

const pageSize = 5;

const CartsList = (props) => {

  const [ carts, setCarts ] = useState([]);
  const [ firstCart, setFirstCart ] = useState();
  const [ lastCart, setLastCart ] = useState();

  const getInitialCarts = async() => {
    const snap = await props.firebase.db.collection('carts').doc(props.user?.id).collection('history').orderBy('completedAt', 'desc').limit(pageSize).get();
    const crts = [];
    snap.forEach(s => {
      crts.push({ref: s, id: s.id, ...s.data()})
    });
    setCarts(crts);
    setFirstCart(crts[0]?.ref)

    // get last element in collection
    const lastSnap = await props.firebase.db.collection('carts').doc(props.user?.id).collection('history').orderBy('completedAt', 'asc').limit(1).get();
    lastSnap.forEach(s => {
      setLastCart(s);
    })
  }

  const cartsPaginationChange = async(direction) => {
    let snap = [];
    if(direction === 'next') {
      const lastElement = carts[carts.length - 1]?.ref;
      snap = await props.firebase.db.collection('carts').doc(props.user?.id).collection('history').orderBy('completedAt', 'desc').startAfter(lastElement).limit(pageSize).get();
    } else if (direction === 'previous') {
      const firstElement = carts[0]?.ref;
      snap = await props.firebase.db.collection('carts').doc(props.user?.id).collection('history').orderBy('completedAt', 'desc').endBefore(firstElement).limit(pageSize).get();
    }

    if(snap?.size) {
      let crts = [];
      snap.forEach(s => {
        crts.push({ref: s, id: s.id, ...s.data()})
      })
      setCarts(crts);
    }
  }

  useEffect(() => {
    getInitialCarts();
  }, []);

  return(
    <Card className="my-2 shadow">  
      <CardBody>
        <div className="d-flex">
          <h5 className="flex-grow-1"><i className="fas fa-shopping-cart"></i> Bestellungen</h5>
        </div>  
        <div className="mt-2">
          <Table>
            <thead>
              <tr>
                <th>Datum</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {carts.map(p => 
                <tr key={p.id}>
                  <td>{String(moment(p.createdAt.toDate()).format('DD.MM.YYYY HH:mm:ss'))}</td>
                  <td><a href={"/cart/" + p.id}>Bestellung ansehen...</a></td>
                </tr>  
              )}
            </tbody>
          </Table>
          <div className="float-right">
            <Button color="link" disabled={carts[0]?.ref?.id === firstCart?.id} className="btn-thin" onClick={() => cartsPaginationChange('previous')}><i className="fas fa-chevron-left"></i></Button>
            <Button color="link" disabled={carts[carts.length - 1]?.ref?.id === lastCart?.id} className="btn-thin" onClick={() => cartsPaginationChange('next')}><i className="fas fa-chevron-right"></i></Button>
          </div>
        </div>
        

      </CardBody>
    </Card>   
  )
}

export default CartsList;


/*

- c: billing_accounts
  - d: <ID>
    stripe_customer_id
    stripe_setup_secret
    credit


*/