import React from 'react';

import { Button } from 'reactstrap';

const ConfirmBooking = () => {

  return (
    <div>
      <center>
      Unten links sehen Sie den Totalbetrag Ihrer Buchung. Mit Klick auf "Buchen" wird Ihnen eine Aufstellung der ausgewählen Slots angezeigt und können Ihre Buchung bestätigen.
      <img  src="/GettingStarted/confirmBooking.png" className="img-fluid mx-auto d-block my-2" />
      </center>
      
    </div>
  )
}

export default ConfirmBooking;