import React, { useState, useEffect } from 'react';

import { AuthUserContext, withAuthorization } from '../_auth/Session';

import { compose } from 'recompose';

import { withFirebase } from '../Firebase';

import {  Container, Row, Col, Button, ButtonGroup, Card, CardBody } from 'reactstrap';

import { LoadScript } from '@react-google-maps/api';

import Gallery from './gallery';
import MapContainer from './map_test';
import DealCard from './DealCard';

import {literals} from '../../_constants/literals'
import Loading from '../Loading';
import Footer from '../Footer';
import { GAEvent } from '../GA/tracker';

import PlacesSearch from './PlacesSearch';
import getDistance from 'geolib/es/getDistance';

import { useStateWithLocalStorage } from "../_localStorage";

const ScreensBase = (props) => {
  
  const [ toggle, setToggle ] = useState(true);

  const [ loadingColorboards, setLoadingColorboards ] = useState(false);
  const [ loadingDeals, setLoadingDeals ] = useState(false);

  const [ colorboards, setColorboards ] = useState([]);
  const [ deals, setDeals ] = useState([]);

  const [ hideIntro, setHideIntro ] = useStateWithLocalStorage('hideIntro');

  const fetchColorboards = async() => {
    setLoadingColorboards(true);
    const cbs = [];
    let logos = [];
    const snaps = await props.firebase.colorboards().where('public', '==', true).get();
    snaps.forEach(async(snap) => {
      let cb = snap.data();
      if(cb.network && cb.network.logo && cb.network.name) {
        logos.push(cb.network.logo)
      }
      cbs.push({...cb, id: snap.id})
    })

    // fetch logos
    const fetchedLogos = {};
    logos = logos.filter((value,index,self) => self.indexOf(value) === index);
    for(const logo of logos) {
      try {
        let downloadLink = await props.firebase.storageRef().child(logo).getDownloadURL();
        fetchedLogos[logo] = downloadLink;
      } catch {
        fetchedLogos[logo] = null;
      }
    }
    // add logo to cb
    cbs.forEach(cb => {
      if(cb.network && cb.network.logo) {
        cb.network.logo = fetchedLogos[cb.network.logo];
        return cb;
      }
    })

    cbs.sort((a,b) => (a.address > b.address) ? 1 : (a.address < b.address) ? -1 : 0);
    return cbs;
  }

  const fetchDeals = async() => {;
    setLoadingDeals(true);
    const dls = [];
    let logos = [];

    const snaps = await props.firebase.deals().where('public', '==', true).get();
    snaps.forEach(async(deal) => {
      let dl = deal.data();
      if(dl.network && dl.network.logo && dl.network.name) {
        logos.push(dl.network.logo)
      }
      dls.push({...dl, id: deal.id})
    })

    // fetch logos
    const fetchedLogos = {};
    logos = logos.filter((value,index,self) => self.indexOf(value) === index);
    for(const logo of logos) {
      try {
        let downloadLink = await props.firebase.storageRef().child(logo).getDownloadURL();
        fetchedLogos[logo] = downloadLink;
      } catch {
        fetchedLogos[logo] = null;
      }
    }
    // add logo to cb
    dls.forEach(dl => {
      if(dl.network && dl.network.logo) {
        dl.network.logo = fetchedLogos[dl.network.logo];
        return dl;
      }
    })

    dls.sort((a,b) => (a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0)
    return dls;
  }

  // geolocation sort
  const geoSort = async(geopoint) => {
    let newCbs = [...colorboards];
    newCbs.forEach(cb => {
      let distance = 0;
      if(cb.geolocation) {
        distance = getDistance(
          {latitude: cb.geolocation.latitude, longitude: cb.geolocation.longitude},
          {latitude: geopoint.lat, longitude: geopoint.lng}
        )
      }
      cb.distance = distance;
      return cb;
    })
    newCbs.sort((a,b) => (a.distance > b.distance) ? 1 : (a.distance < b.distance) ? -1 : 0)
    setColorboards(newCbs);
  }

  useEffect(() => {
    console.log('screens rendered')
    let mounted = true;
    setLoadingColorboards(true);
    fetchColorboards().then(cbs => {
      if(mounted) {
        setColorboards(cbs);
        setLoadingColorboards(false);
      }
    })
    return () => mounted = false;
  }, []);

  useEffect(() => {
    let mounted = true;
    setLoadingDeals(true);
    fetchDeals().then(dls => {
      if(mounted) {
        setDeals(dls);
        setLoadingDeals(false);
      }
    })
    return () => mounted = false;
  }, []);

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY} libraries={['places']}>
      {toggle ?
          <>
          {!hideIntro && 
            <Container className="pageContent">
              <Card>
                <CardBody>
                  <Button className="close float-right" color="link" aria-label="Close" onClick={() => setHideIntro(true)}><span aria-hidden="true">&times;</span></Button>
                    <p>Willkommen auf der BiddAd Plattform</p>
                    Wählen Sie einen Deal oder Buchen Sie direkt einzelne Screens, laden Sie Ihren Inhalt hoch und zeigen Sie diesen Ihrem Publikum. Bei Fragen stehen wir Ihnen unter <a href="mailto:feedback@biddad.com">feedback@biddad.com</a> gerne zur Verfügung. Weiter Infos finden Sie auf <a href="https://www.biddad.com">www.biddad.com</a>.
                </CardBody>
              </Card>
            </Container>
          }

          {loadingDeals ? <Loading /> : deals.length > 0 && 
            <Container className="pageContent">
              <h4 className="pageTitle">Deals</h4>
              <Row>
                {deals.map(d => <Col lg="4" sm="6" key={d.id}><DealCard deal={d}/></Col>)}
              </Row>
              <br/>
              <span className="float-right"><a href="mailto:feedback@biddad.com?subject=Keinen passenden Deal">Keinen passenden Deal? Kontaktieren Sie uns...</a></span>
            </Container>
          }

          {loadingColorboards ? <Loading /> : colorboards.length > 0 && 
            <Container className="pageContent">
              <Row className="pageTitle">
                <Col><h4>Screens</h4></Col>
                <Col>
                  <ButtonGroup className="float-right">
                      <Button color="link" outline onClick={() => {GAEvent('Screens', 'show gallery'); setToggle(!toggle)}}><i className="fas fa-th"></i> {literals.gallery}</Button>
                      <Button color="link" className="btn-thin"  onClick={() => {GAEvent('Screens', 'show map'); setToggle(!toggle)}}><i className="fas fa-map-marked-alt"></i> {literals.map}</Button>
                  </ButtonGroup>
                </Col>
              </Row>
              <Row>
                <Col></Col>
                <Col lg="4" sm="6"><PlacesSearch geoSort={geoSort}/></Col>
              </Row>
              <Gallery colorboards={colorboards}/>
            </Container>
          }
          </>
        :
          loadingColorboards ? <Loading /> : colorboards.length > 0 &&
            <>
              <Row>
                <Col>
                <ButtonGroup className="float-right">
                    <Button color="link" className="btn-thin"  onClick={() => {GAEvent('Screens', 'show gallery'); setToggle(!toggle)}}><i className="fas fa-th"></i> {literals.gallery}</Button>
                    <Button color="link" outline onClick={() => {GAEvent('Screens', 'show map'); setToggle(!toggle)}}><i className="fas fa-map-marked-alt"></i> {literals.map}</Button>
                </ButtonGroup>
                </Col>
              </Row>
              <MapContainer colorboards={colorboards}/>
            </>  
      }
      <Footer/>
    </LoadScript>
  );
};

const Screens = compose(
  withFirebase,
)(ScreensBase)

export default Screens;