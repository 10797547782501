import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';

import { Container, Row, Col } from 'reactstrap';

import { withAuthorization } from '../_auth/Session';
import { withFirebase } from '../Firebase';
import Footer from '../Footer';

import Loading from '../Loading';

import Slots from './slots';
import Deal from './deal';
import Checkout from './checkout';
import AwaitCheckout from './awaitCheckout';

const CartBase = (props) => {

  const [ cart, setCart ] = useState();
  const [ loading, setLoading ] = useState(false);

  const [ total, setTotal ] = useState(0);
  const [ totalMwst, setTotalMwst ] = useState(0)

  useEffect(() => {
    setLoading(true);
    const unsubscribe = props.firebase.db.collection('carts').doc(props.uid).onSnapshot(snap => {
      const data = snap.data();
      setCart(data);
      setLoading(false)
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    let t = 0;
    cart && cart.slots && cart.slots.length>0 && cart.slots.forEach(s => {t += s.totalDiscount;});
    cart && cart.deals && cart.deals.length>0 && cart.deals.forEach(d => {t += d.total; });
    setTotal(t);
  }, [cart]);

  useEffect(() => {
    setTotalMwst(Math.round(1.077 * total * 100)/100)
  }, [total]);

  return(
    <>
      {loading ? <Loading /> :
        <Container className="pageContent">
          {cart?.status ? <AwaitCheckout status={cart?.status}/> : 
          <>
            <Row className="pageTitle">
              <Col><h4>Warenkorb</h4></Col>
            </Row>
            {cart && cart.createdAt ?
            <>
              <Checkout uid={props.uid} total={total} totalMwst={totalMwst} setLoading={setLoading} />
              <br />
              <h5>Positionen:</h5>
              {cart.deals?.map(d => <Deal deal={d} firebase={props.firebase} key={d.addedAt} uid={props.uid} editable={true} />)}
              {cart.slots?.map(s => <Slots slot={s} firebase={props.firebase} key={s.addedAt} uid={props.uid} editable={true} />)}
            </>
            : (cart?.latestCart ?
                <p>Vielen Dank für Deine Buchung! Verfolge den Status der Buchung auf <a href="/mybookings">Bookings</a></p> :
                <p>Der Warenkorb ist leer</p>
              )
            }
          </>
          }
        </Container>
      }
      <br />
      <Footer/>
    </>
  )

}

const condition = authUser => !!authUser;

const Cart = compose(
  withAuthorization(condition),
  withFirebase,
)(CartBase)

export default Cart;