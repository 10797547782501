import React, { useState, useEffect } from 'react';

import { compose } from 'recompose';

import {  Button, Card, Badge, CardBody, Container, Row, Col } from 'reactstrap';

import { withAuthorization } from '../_auth/Session';

import ScreenCard from './ScreenCard';
import ReviewModal from './ReviewModal'

import MyDeals from './MyDeals';

import Loading from '../Loading';
import Footer from '../Footer';
import { withFirebase } from '../Firebase';

const MyPropertyBase = (props) => {

  const [ loadUpcoming, setLoadUpcoming ] = useState(false);
  const [ loadScreens, setLoadSecreens ] = useState(false);

  const [ colorboards, setColorboards ] = useState([]);
  const [ upcomingBookings, setUpcomingBookings ] = useState();

  const [ reviewModalOpen, setReviewModalOpen ] = useState(false);

  const fetchColorboards = async() => {
    const cbs = [];
    let logos = [];

    const myCbs = props.authUser?.claims?.publisherColorboards || [];

    await Promise.all(myCbs.map(async(cb) => {
      const cbdata = (await props.firebase.db.collection('colorboards').doc(cb).get()).data();
      if(cbdata.network && cbdata.network.logo && cbdata.network.name) {
        logos.push(cbdata.network.logo);
      }
      cbs.push({...cbdata, id: cb});
    }))

    // fetch logos
    const fetchedLogos = {};
    logos = logos.filter((value,index,self) => self.indexOf(value) === index);
    for(const logo of logos) {
      try {
        let downloadLink = await props.firebase.storageRef().child(logo).getDownloadURL();
        fetchedLogos[logo] = downloadLink;
      } catch {
        fetchedLogos[logo] = null;
      }
    }
    // add logo to cb
    cbs.forEach(cb => {
      if(cb.network && cb.network.logo) {
        cb.network.logo = fetchedLogos[cb.network.logo];
        return cb;
      }
    })

    cbs.sort((a,b) => (a.address > b.address) ? 1 : (a.address < b.address) ? -1 : 0);
    setColorboards(cbs);
  }

  const getUpcomingBookings = async() => {
    if(props.authUser && props.authUser.claims && props.authUser.claims.publisherColorboards) {
      setLoadUpcoming(true);
      const bookings = [];
      const myCbs = props.authUser.claims.publisherColorboards;
      let userIds = [];
      await Promise.all(myCbs.map(async(cb) => {
        const s1 = await props.firebase.db.collection('colorboards').doc(cb).collection('bookings').where('end', '>=', new Date()).get();
        s1.forEach(s => {
          const sData = s.data();
          bookings.push({cbid: cb, id: s.id, ...sData});
          userIds.push(sData.user);
        })
      }));

      const fetchedUsers = {};
      userIds = userIds.filter((value, index, self) => self.indexOf(value) === index);
      for(const u of userIds) {
        try{
          const userRef = await props.firebase.db.doc(u).get();
          fetchedUsers[u] = userRef.data();
        } catch {
          fetchedUsers[u] = null;
        }
      }
      // add userEmail to bookings;
      bookings.forEach(b => {
        b.user = fetchedUsers[b.user].email;
      })

      setUpcomingBookings(bookings);
      setLoadUpcoming(false);
    }
  }

  useEffect(() => {
    getUpcomingBookings();
    fetchColorboards();
  }, [])

  return(
    <>
      <Container className="pageContent">
          <MyDeals authUser={props.authUser} firebase={props.firebase}/>
          <h5 className="pageTitle">Publisher - Screens</h5>
          {loadUpcoming ? <Loading /> : upcomingBookings && 
            <>
            <Card className="my-2 shadow">
              <CardBody>
              <Row>
                <Col md="10" xs="8">
                  Es gibt {upcomingBookings.filter(b => b.status === 'pending').length} <Badge color="warning">pending</Badge> Bookings
                  auf {[...new Set(upcomingBookings.filter(b => b.status === 'pending').map(b => b.cbid))].length} Screen(s)
                </Col>
                <Col md="2" xs="4">
                  {upcomingBookings.filter(b => b.status === 'pending').length > 0 && <Button block color="primary" outline style={{height: "100%"}} onClick={() => setReviewModalOpen(true)}>Review Bookings</Button>}
                </Col>
              </Row>
              </CardBody>
            </Card>
            <ReviewModal isOpen={reviewModalOpen} setIsOpen={setReviewModalOpen} getUpcomingBookings={getUpcomingBookings} upcomingPending={upcomingBookings.filter(b => b.status === 'pending')} colorboards={colorboards} firebase={props.firebase} />
            </>
          }
          {loadScreens ? <Loading /> : colorboards && 
            <Row>
              {colorboards.map(cb => 
                <Col key={cb.id} lg="4" sm="6"><ScreenCard cb={cb} upcomingBookings={upcomingBookings && upcomingBookings.filter(b => b.cbid === cb.id)} /></Col>
              )}
            </Row>
          }
      </Container>
      <Footer/>
    </>

  )
}

// const initialState = {
//   loading: false,
//   colorboards: [],
// }


// class MyPropertyBase extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { ...initialState };
//   }

//   componentDidMount = async () => {

//   }

//   render() {
//     const { loading } = this.state;
//     return (
//       <>
//       <Container className="pageContent">
//         {loading ? (<Loading />) : (
//           <>
//             <h5 className="pageTitle">My Screens</h5>
//             <AuthUserContext.Consumer>
//             {authUser => (
//               <>
//                 {authUser.claims && authUser.claims.publisherColorboards &&  authUser.claims.publisherColorboards.length > 0 && authUser.claims.publisherColorboards.map((cbid, index) => (
//                   <MyColorBoard key={index} cbid={cbid} />
//                 ))
//                 }
//               </>
//             )}
//             </AuthUserContext.Consumer>
//           </>
//         )}
//         <br/>
//       </Container>
//       <Footer/>
//       </>
//     )
//   }
// };

const condition = authUser => !!authUser;

const MyProperty = compose(
  withAuthorization(condition),
  withFirebase,
)(MyPropertyBase)

export default MyProperty;