import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import ReactGA from "react-ga";
import TagManager from 'react-gtm-module';
import { RecoilRoot } from 'recoil';
// Style sheet
import styles from '../style.css';

import Navigation from '../Navigation';
import AccountPage from '../Account';

import Screens from '../Screens/index.js';
import AutoLogin from '../AutoLogin';
import Book from '../Book2020/index.js';
import BookDeal from '../Deal/BookDeal';
// import Book from '../Book';
import MyBookingsPage from '../MyBookings/myBookings';

import ScreenBookings from '../MyProperty/Screens/ScreenBookings';
import MyPropertyPage from '../MyProperty';

import Cart from '../Cart';
import CartHistory from'../Cart/cartHistory';

import { withAuthentication } from '../_auth/Session';

import withTracker from '../GA/withTracker';

import * as ROUTES from '../../_constants/routes';

ReactGA.initialize(process.env.REACT_APP_GA_ID);

const tagManagerArgs = { gtmId: process.env.REACT_APP_GTM_ID};
TagManager.initialize(tagManagerArgs);

// const trackedColorBoardDetails = withTracker(ColorBoardDetails);
const trackedScreens = withTracker(Screens);
const trackedMyBookingsPage = withTracker(MyBookingsPage);

const trackedMyPropertyScreenBookings = withTracker(ScreenBookings);
const trackedMyPropertyPage = withTracker(MyPropertyPage);

const trackedAccountPage = withTracker(AccountPage);
const trackedCartHistory = withTracker(CartHistory);
const trackedCart = withTracker(Cart);

const trackedBook = withTracker(Book);
const trackedBookDeal = withTracker(BookDeal);

const trackedAutoLogin = withTracker(AutoLogin);

const App = () => (
<Router>
  <RecoilRoot>
    <Navigation />
    <div className="content m-2">
      <Switch>
        {/*<Route exact path={ROUTES.LANDING} component={LandingPage} />*/}

        {/* <Route path={ROUTES.COLORBOARDDETAILS} component={trackedColorBoardDetails} /> */}

        {/* <Route path={ROUTES.SCREENSMAP} component={trackedColorBoardsMap} />
        <Route path={ROUTES.SCREENSGALLERY} component={trackedColorBoardsGallery} /> */}
        <Route path={ROUTES.SCREENS} component={trackedScreens} />
        <Route path={ROUTES.AUTOLOGIN} component={trackedAutoLogin} />
        <Route path={ROUTES.BOOKSCREEN} component={trackedBook} />
        <Route path={ROUTES.BOOKDEAL} component={trackedBookDeal} />

        <Route path={ROUTES.MYBOOKINGS} component={trackedMyBookingsPage} />

        <Route path={ROUTES.MYPROPERTY_SCREENBOOKINGS} component={trackedMyPropertyScreenBookings} />
        <Route path={ROUTES.MYPROPERTY} component={trackedMyPropertyPage} />

        <Route path={ROUTES.ACCOUNT} component={trackedAccountPage} />

        <Route path={ROUTES.CARTHISTORY} component={trackedCartHistory} />
        <Route path={ROUTES.CART} component={trackedCart} />

        {/*temporary as long as we only have 1 colorboard */}
        {/* <Route path="*" render={() => (<Redirect to="/colorboards/UYUKyjM3UdNRbR0H9PDU" />)} /> */}
        {/* <Route path="*" render={() => (<Redirect to="/colorboards/3fS9aUrVjr10iAFB17jG" />)} /> */}

        <Route path="*" render={() => (<Redirect to={ROUTES.SCREENS} />)} />

      </Switch>
    </div>
  </RecoilRoot>
</Router>
)

export default withAuthentication(App);