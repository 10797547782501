import React, { useState, useEffect } from 'react';


import moment from 'moment';

import {  Badge, Container, Row, Col, Table } from 'reactstrap';

import Loading from '../Loading';

const Deals = (props) => {

  const [ loadingDealBookings, setLoadingDealBookings ] = useState(false);
  const [ dealBookings, setDealBookings ] = useState([]);

  const getCBandMedia = async (uniqCBIds, uniqMedia) => {
    const colorboards = {};
    const media = {};

    await Promise.all(uniqCBIds.map(async(cb) => {
      const cData = (await props.firebase.colorboard(cb).get()).data();
      colorboards[cb] = {address: cData.address};
    }));

    await Promise.all(uniqMedia.map(async(m)=> {
      const mData = (await props.firebase.db.doc(m).get()).data();
      const downloadLink = await props.firebase.storageRef().child(mData.fullPath).getDownloadURL();
      media[m] = {displayname: mData.displayname, downloadLink }
    }))
    
    return [colorboards, media];
  }

  const loadDealBookings = async() => {
    setLoadingDealBookings(true);

    let myDealBookings = [];

    const s1 = await props.firebase.db.collectionGroup('dealBookings').where('user', '==', '/users/' + props.uid).get();

    s1.forEach(doc => {
      myDealBookings.push({id: doc.id, dealId: doc.ref.parent.parent.id, ...doc.data()})
    })

    // Array of uniq Deal IDs (to not fetch them multiple times)
    const uniqDealIds = [...new Set(myDealBookings.map(item => item.dealId))];

    const cbIds = [];
    const dealCB = {};
    const deals = {};

    // fetch deals to get the colorboards
    await Promise.all(uniqDealIds.map(async(d) => {
      const dealData = (await props.firebase.db.doc('/deals/' + d).get()).data();
      deals[d] = dealData;
      dealData.colorboards.forEach(cb => {
        cbIds.push(cb.id)
        if(dealCB[d]) {
          dealCB[d].push({cbid: cb.id})
        } else {
          dealCB[d] = [{cbid: cb.id}];
        }
      })
    }))

    const uniqMedia = [...new Set(myDealBookings.map(item => item.media))];
    const uniqCBIds = [...new Set(cbIds.map(item => item))];

    let colorboards, media;
    [colorboards, media] = await getCBandMedia(uniqCBIds, uniqMedia);

    uniqDealIds.forEach(dId => {
      dealCB[dId].forEach((cb, index) => {
        dealCB[dId][index] = {...dealCB[dId][index], ...colorboards[dealCB[dId][index].cbid]}
      })
    })
    
    myDealBookings = myDealBookings.map(b => {
      const dealBooking = b;
      dealBooking.media = media[b.media];
      dealBooking.cbs = dealCB[b.dealId];
      dealBooking.dealName = deals[b.dealId].name;
      return dealBooking;
    })
    setDealBookings(myDealBookings);
    setLoadingDealBookings(false);

  }

  useEffect(() => {
    loadDealBookings();
  }, [])

  return (
    loadingDealBookings ? <Loading /> : dealBookings.length > 0 &&
      <Container className="pageContent">
        <Row>
          <Col>
            <h5 className="pageTitle">Meine Deal Bookings</h5>
          </Col>
          <Col>
            {/* <ButtonGroup className="float-right">
                <Button color="link" className="btn-thin" outline={fil === 12}onClick={() => updateFilter(12)}><i className="fas fa-history"></i> 12 Monate</Button>
                <Button color="link" className="btn-thin" outline={fil === 3} onClick={() => updateFilter(3)}><i className="fas fa-history"></i> 3 Monate</Button>
            </ButtonGroup> */}
          </Col>
        </Row>
        <Table>
          <thead>
            <tr>
              <th>Status</th>
              <th>Deal</th>
              <th>Screens</th>
              <th>Start</th>
              <th>Ende</th>
              <th>Media</th>
              <th>Preis</th>
            </tr>
          </thead>
          <tbody>
            {
              dealBookings.map(booking => (
                <tr key={booking.id}>
                  <td>{
                    booking.status === 'confirmed' ? (
                      <Badge color="success">bestätigt</Badge>
                    ) : (
                      booking.status === 'pending' ? (
                        <Badge color="warning">ausstehend</Badge>
                      ) : (
                        <Badge color="danger">abgelehnt</Badge>
                      )
                    )
                  }</td>
                  <td><a href={"/deal/" + booking.dealId} target="_blank" rel="noopener noreferrer">{booking.dealName}</a></td>
                  <td>
                    {booking.cbs.map(cb => 
                      <span key={cb.cbid}><a href={"/book/" + cb.cbid} target="_blank" rel="noopener noreferrer">{cb.address}</a><br /></span>
                    )}
                  </td>
                  <td>
                    {booking.slots.map((slot, index) => 
                      <span key={index}>{String(moment(slot.start.toDate()).format('DD.MM.YYYY'))}<br /></span>
                    )}
                  </td>
                  <td>
                    {booking.slots.map((slot, index) => 
                      <span key={index}>{String(moment(slot.end.toDate()).format('DD.MM.YYYY'))}<br /></span>
                    )}
                  </td>
                  <td><a href={booking.media.downloadLink} target="_blank" rel="noopener noreferrer">{booking.media.displayname}</a></td>
                  <td>{booking.status === 'declined' ? (<del>{booking.price.toFixed(2) + ' CHF'}</del>):(booking.price.toFixed(2) + ' CHF')}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Container>
  )
}

export default Deals;
