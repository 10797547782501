import React from 'react';

import { Alert, Modal, ModalHeader } from 'reactstrap'

import Login from './login';
import PasswordForget from './passwordForget';
import Signup from './signup';

const initialState = {
  mode: 'login', // login, signup, passwordforget
  development: false,
}

class AuthModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };

    this.changeMode = this.changeMode.bind(this);
  }

  componentDidMount = () => {
    if (process.env.REACT_APP_BIDDAD_ENV === 'development') this.setState({development: true});
    //console.log('auth modal rendered')
  }

  changeMode = (mode) => {
    this.setState({mode});
  }


  render() {
    const { mode, development } = this.state;

    return(
      <Modal isOpen={this.props.isOpen} autoFocus={false}>
        <div>
          {development && <Alert color="warning">This is DEVELOPMENT and shouldn't be used<br /> --> bring be to <a href="https://app.biddad.com">PRODUCTION</a></Alert>}
          {mode === 'login' ? <div><ModalHeader>Login</ModalHeader><Login firebase={this.props.firebase} setLoading={this.setLoading} changeMode={this.changeMode}/></div> :
          mode === 'passwordforget' ? <div><ModalHeader>Passwort zurücksetzen</ModalHeader><PasswordForget firebase={this.props.firebase} setLoading={this.setLoading} changeMode={this.changeMode}/></div> :
          mode === 'signup' && <div><ModalHeader>Registrieren</ModalHeader><Signup firebase={this.props.firebase} setLoading={this.setLoading} changeMode={this.changeMode}/></div>} 
        </div>
      </Modal>
    )
  }
}

export default AuthModal;
