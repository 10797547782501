import React from 'react';

import { Button } from 'reactstrap';

const Intro = () => {

  return (
    <div>
      <center>
      Danke, dass Sie sich auf BiddAd registriert haben.<br/><br/>
      Klicken Sie auf "start"!
      </center>
      {/* <img  src="/GettingStarted/Neumarkt.jpg" className="img-fluid mx-auto d-block my-2" width="80%" /> */}
    </div>
  )
}

export default Intro;