import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import AuthUserContext from './context';
import { withFirebase } from '../../Firebase';
import { GASet } from '../../GA/tracker';

import AuthModal from '../AuthModal';

const INITIAL_STATE = {
  loginModalOpen: false,
};

const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {

    constructor(props) {
      super(props);
  
      this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          if (!condition(authUser)) {
            this.setState({loginModalOpen: true});
          } else {
            this.setState({loginModalOpen: false});
            GASet({userId: authUser.uid});
          }
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            condition(authUser) ? <Component {...this.props} authUser={authUser} uid={authUser.uid}/> : <AuthModal isOpen={this.state.loginModalOpen} firebase={this.props.firebase} />
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return compose(
    withRouter,
    withFirebase,
  )(WithAuthorization);
};

export default withAuthorization;