import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Alert, Button, Card, CardBody } from "reactstrap";

import DateRangePicker from './DateRangePicker';
import Loading from '../Loading';
import {literals} from '../../_constants/literals'

import { GAEvent } from '../GA/tracker';

const SelectSlots = (props) => {

  const selectFullDay = (day) => {
    GAEvent('Book', 'FullDaySelected: ' + day)
    const tStamps = [];
    for(let s of props.slots) {
      if(day === String(moment(s.start).format('DD.MM.YYYY'))) {
        tStamps.push(s.start)
      }
    }
    tStamps.length && props.selectMultipleSlots(tStamps)
  }

  const createAvailableElems = () => {
    let elems = [];
    let date = null;

    const sPerDay = new Map();
    for(let s of props.slots) {
      if(s.visible) {
        const day = String(moment(s.start).format('DD.MM.YYYY'));
        if(sPerDay.has(day)) {
          const newValue = [...sPerDay.get(day)];
          newValue.push(s);
          sPerDay.set(day, newValue)
        } else {
          sPerDay.set(day, [s])
        }
      }
    }

    sPerDay.forEach((value, key) => {     
      if(props.discount?.day && value.length >= props.discount?.day?.slots) {
        // discount available --> add label and "ganzer tag buchen button"
        elems.push(
          <span key={key}>{key}<br/>
            <Button className="float-right btn-round m-1" color="primary" size="sm" onClick={() => selectFullDay(key)} outline>
              Ganzer Tag{props.discount?.day?.discount && <span> (-{props.discount?.day?.discount*100}%)</span>}
            </Button>      
          </span>
        )
      } else {
        // no discount available
        elems.push(<span key={key}>{key}<br/></span>)
      }
      value.forEach(s => {
        elems.push(
          <Button style={{width: "200px"}} key={s.start} className="btn-round m-1" color="primary" size="sm" onClick={() => props.toggleSlot(s.start)} outline={!s.selected}>
            {" " + String(moment(s.start).format('HH:mm') + ' - ' + String(moment(s.end).format('HH:mm')) + ' (' + s.price.toFixed(2) + ' CHF)')}
          </Button>
        )
      })
      elems.push(<br key={"br_" + key}/>)
    })

    if(elems.length === 0) {
      elems.push(<Alert color="warning" key={"alert_noslotsavailable"}>Im ausgewähltem Zeiteraum sind keine Slots verfügbar. Wählen Sie einen anderen Zeitraum.</Alert>)
    }

    return(elems)
  }
  
  return(
    <>
      <small>{literals.selectSlotAvailable}</small>
      <Card>
        <CardBody className="py-2">
          <DateRangePicker start={props.dateRange.start} end={props.dateRange.end} callback={props.updateDateRange} selectAllSlots={props.selectAllSlots}/>
        </CardBody>
      </Card>
      <Card className="p-2">
        <CardBody>
          {props.loadingSlots ? <Loading /> : 
            <div className="overflow-auto" style={{"maxHeight" : "300px"}}>
              {createAvailableElems()}
            </div> 
          }         
        </CardBody>
      </Card>
      <br />
      {props.slots.findIndex(obj => obj.selected) !== -1 &&
      <div>
        <small>{literals.selectSlotSelected}</small>
        <Card className="p-2">
          <CardBody>
            <div className="overflow-auto" style={{"maxHeight" : "300px"}}>
            {props.slots.map(s => 
              s.selected && !s.booked && <Button style={{width: "260px"}} key={s.start} className="btn-round m-1" color="primary" size="sm" onClick={() => props.toggleSlot(s.start)}><i className="nc-icon nc-simple-remove" />
              {" " + String(moment(s.start).format('DD.MM.YYYY HH:mm') + ' - ' + String(moment(s.end).format('HH:mm')) + ' (' + s.price.toFixed(2) + ' CHF)')}
              </Button>
            )}
            </div>
          </CardBody>
        </Card>
      </div>
      }
    </>
  )
}

export default SelectSlots;