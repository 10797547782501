import React, { useState, useEffect } from 'react';

import { compose } from 'recompose';

import moment from 'moment';

import {  Button, ButtonGroup, Table, Badge, Container, Row, Col } from 'reactstrap';

import { withAuthorization, AuthUserContext } from '../../_auth/Session';

import Footer from '../../Footer';
import { withFirebase } from '../../Firebase';
import { useLocation } from 'react-router-dom';

import Loading from '../../Loading';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

/* filters
  screen: <String> Screen ID
  status: <String> confirmed, pending, declined
*/
const ScreenBookingsBase = (props) => {

  const [ loading, setLoading ] = useState(false);
  const [ fil, setFil ] = useState(0);

  const [ screen, setScreen ] = useState(null);
  const [ screenBookings, setScreenBookings ] = useState([]);

  const [ mode, setMode ] = useState()

  const loadBookings = async() => {
    setLoading(true);

    let bookings = [];
    const media = new Map();
    const users = new Map();

    const cbid = new URLSearchParams(props.location.search).get('screen');
    const endFilter = new Date();
    endFilter.setMonth(endFilter.getMonth() - fil);

    if(cbid) {
      const cbRef = props.firebase.db.collection('colorboards').doc(cbid);
      const cb = (await cbRef.get()).data();
      setScreen({id: cbRef.id, ...cb});
      const bookingRefs = await cbRef.collection('bookings').where('end', '>=', endFilter).get();

      bookingRefs.forEach(b => {
        const bData = b.data();
        bookings.push({id: b.id, visible: true, selected: false, ...bData})
        bData.media && !media.has(bData.media) && media.set(bData.media, {displayname: '', downloadLink: ''});
        bData.user && !users.has(bData.user) && users.set(bData.user, {email:''});
      })

      // load media
      for (let m of media.keys()) {
        const mData = (await props.firebase.db.doc(m).get()).data();
        const downloadLink = await props.firebase.storageRef().child(mData.fullPath).getDownloadURL();
        media.set(m, {displayname: mData.displayname, downloadLink})
      }

      // load users
      for (let u of users.keys()) {
        const userData = (await props.firebase.db.doc(u).get()).data();
        users.set(u, {email: userData?.email});
      }

      bookings = bookings.map(b => {
        return {...b, media: media.get(b.media), user: users.get(b.user)};
      })
    }
    setScreenBookings(bookings);
    setLoading(false);
  }

  const confirmMode = () => {
    setMode('confirm');
    let bookings = screenBookings;
    bookings.map(b => {
      b.selected = false;
      if(new Date(b.start.toDate()) > new Date() && b.status === 'pending') {
        b.visible = true;
      } else {
        b.visible = false;
      }
    })
    setScreenBookings([...bookings])
  }

  const declineMode = () => {
    setMode('decline');
    let bookings = screenBookings;
    bookings.map(b => {
      b.selected = false;
      if(new Date(b.start.toDate()) > new Date() && b.status !== 'declined') {
        b.visible = true;
      } else {
        b.visible = false;
      }
    })
    setScreenBookings([...bookings])
  }

  const resetMode = () => {
    let bookings = screenBookings;
    bookings.map(b => {
      b.selected = false;
      b.visible = true;
    })
    setScreenBookings([...bookings]);
    setMode();
  }

  const checkSingle = (index) => {
    let bookings = screenBookings;
    bookings[index].selected = !bookings[index].selected;
    setScreenBookings([...bookings]);
  }

  const confirm = async() => {
    setLoading(true);
    let bids = [];
    screenBookings.map(b => {
      b.selected && bids.push(b.id)
    })
    await props.firebase.reviewBookingCallable({cid: screen.id, bids, method: 'confirm'});
    setMode();
    loadBookings();
  }

  const decline = async() => {
    setLoading(true);
    let bids = [];
    screenBookings.map(b => {
      b.selected && bids.push(b.id)
    })
    await props.firebase.reviewBookingCallable({cid: screen.id, bids, method: 'decline'});
    setMode();
    loadBookings();
  }

  const updateFilter = (months) => {
    if(months === fil) {
      setFil(0);          // reset 
    } else {
      setFil(months);
    }
  }

  useEffect(() => {
    loadBookings();
  }, [fil])

  return(
    <>
      {loading ? (<Loading />) : (
        <Container className="pageContent">
          <h5 className="pageTitle">Publisher - {screen?.address}</h5>
          <Row>
            <Col>
              <div>
                {mode == 'confirm' ?
                  <span><Button color="success" onClick={() => confirm()}>Confirm selected</Button><Button outline color="secondary" className="float-right" onClick={resetMode}>X</Button></span>
                : mode == 'decline' ? 
                  <span><Button color="danger" onClick={() => decline()}>Decline selected</Button><Button outline color="secondary" className="float-right" onClick={resetMode}>X</Button></span>
                :
                  <span>
                    <Button color="success" outline onClick={() => confirmMode()}>confirm</Button><span> </span><Button color="danger" outline onClick={() => declineMode()}>decline</Button>
                  </span>}
              </div>
            </Col>
            <Col>
              <ButtonGroup className="float-right">
                <Button color="link" className="btn-thin" outline={fil === 12}onClick={() => updateFilter(12)}><i className="fas fa-history"></i> 12 Monate</Button>
                <Button color="link" className="btn-thin" outline={fil === 3} onClick={() => updateFilter(3)}><i className="fas fa-history"></i> 3 Monate</Button>
              </ButtonGroup>
            </Col>
          </Row>

          <Table>
            <thead>
              <tr>
                {mode && <th></th>}
                <th>Status</th>
                {/* <th>Screen</th> */}
                <th>Start</th>
                <th>Ende</th>
                <th>Media</th>
                <th>User</th>
                <th>Preis</th>
              </tr>
            </thead>
            <tbody>
              {
                screenBookings.map((booking, index) => (booking.visible && 
                  <tr key={booking.id}>
                    {mode && <td><input type="checkbox" checked={booking.selected} onChange={() => checkSingle(index)}/></td>}
                    <td>{
                      booking.status === 'confirmed' ? (
                        <Badge color="success">bestätigt</Badge>
                      ) : (
                        booking.status === 'pending' ? (
                          <Badge color="warning">ausstehend</Badge>
                        ) : (
                          <Badge color="danger">abgelehnt</Badge>
                        )
                      )
                    }</td>
                    {/* <td><a href={"/book/" + booking.id} target="_blank" rel="noopener noreferrer">{booking.colorboard.address}</a></td> */}
                    <td>{String(moment(booking.start.toDate()).format('DD.MM.YYYY HH:mm:ss'))}</td>
                    <td>{String(moment(booking.end.toDate()).format('DD.MM.YYYY HH:mm:ss'))}</td>
                    <td><a href={booking.media.downloadLink} target="_blank" rel="noopener noreferrer">{booking.media.displayname}</a></td>
                    <td>{booking?.user?.email}</td>
                    <td>{booking.status === 'declined' ? (<del>{booking.price.toFixed(2) + ' CHF'}</del>):(booking.price.toFixed(2) + ' CHF')}</td>
                  </tr>
                ))
              }
            </tbody>
          </Table>   
        </Container>
      )}   
      <Footer/>
    </>

  )
}


const condition = authUser => !!authUser;

const ScreenBookings = compose(
  withAuthorization(condition),
  withFirebase,
)(ScreenBookingsBase)

export default ScreenBookings;