import React from 'react';

import { Badge } from 'reactstrap';

const Bookings = () => {

  return (
    <div>
      <center>
      Sie gelangen anschliessend auf "Bookings", wo Sie den Status Ihrer Buchung verfolgen können: <br/><br/>
      <Badge color="success">bestätigt</Badge> Die Buchung ist bestätigt und wird zum gewählten Zeitpunk aufgeschaltet<br/>
      <Badge color="warning">ausstehend</Badge> Der Besitzer des Screens (Publisher) muss Ihre Buchung noch freigeben<br/>
      <Badge color="danger">abgelehnt</Badge> Ihre Buchung wurde vom Publisher abgelehnt<br/>
      </center>
      
    </div>
  )
}

export default Bookings;