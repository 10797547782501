import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import moment from 'moment';

import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';

import { withAuthorization } from '../_auth/Session';
import { withFirebase } from '../Firebase';
import Footer from '../Footer';

import Loading from '../Loading';

import Slots from './slots';
import Deal from './deal';

const CartHistoryBase = (props) => {

  const [ cart, setCart ] = useState();
  const [ loading, setLoading ] = useState(false);

  const [ total, setTotal ] = useState(0);
  const [ totalMwst, setTotalMwst ] = useState(0)

  useEffect(() => {
    setLoading(true);
    const unsubscribe = props.firebase.db.collection('carts').doc(props.uid).collection('history').doc(props.match.params.cid).onSnapshot(snap => {
      const data = snap.data();
      setCart(data);
      setLoading(false)
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    let t = 0;
    cart && cart.slots && cart.slots.length>0 && cart.slots.forEach(s => {t += s.totalDiscount;});
    cart && cart.deals && cart.deals.length>0 && cart.deals.forEach(d => {t += d.total; });
    setTotal(t);
  }, [cart]);

  useEffect(() => {
    setTotalMwst(Math.round(1.077 * total * 100)/100)
  }, [total]);

  return(
    <>
      {loading ? <Loading /> :
        <Container className="pageContent">
          <>
            <Row className="pageTitle">
              <Col><h4>Abgeschlossene Bestellung</h4></Col>
            </Row>
            {cart && cart.createdAt ?
            <>
              <Card>
                <CardBody>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Datum</td>
                        <td>{String(moment(cart?.completedAt?.toDate()).format('DD.MM.YYYY HH:mm'))}</td>
                      </tr>
                      <tr>
                        <td>Total (exkl. Mwst)</td>
                        <td>{total.toFixed(2)} CHF</td>
                      </tr>
                      <tr>
                        <td>Total (inkl. Mwst)</td>
                        <td>{totalMwst.toFixed(2)} CHF</td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
              <br />
              <h5>Positionen:</h5>
              {cart.deals?.map(d => <Deal deal={d} firebase={props.firebase} key={d.addedAt} uid={props.uid} />)}
              {cart.slots?.map(s => <Slots slot={s} firebase={props.firebase} key={s.addedAt} uid={props.uid} />)}
            </>
            :
            <p>Dieser Warenkorb existiert nicht.</p>}
          </>
        </Container>
      }
      <br />
      <Footer/>
    </>
  )

}

const condition = authUser => !!authUser;

const CartHistory = compose(
  withAuthorization(condition),
  withFirebase,
)(CartHistoryBase)

export default CartHistory;