import ReactGA from "react-ga";

export const GAEvent = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};

export const GASet = (value) => {
  ReactGA.set(value);
};

export const GAModal = (value) => {
  ReactGA.modalview(value);
}