import React from 'react';
import { compose } from 'recompose';
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption, Container } from 'reactstrap';

import Loading from '../Loading';
import { withFirebase } from '../Firebase';

import { GAEvent } from '../GA/tracker';

const initialState = {
  activeIndex: 0,
  mediaData: [],
  loading: false,
}

class PhotosBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);

  }

  componentDidMount = async() => {
    this.setState({loading: true});
    let mediaData = [];
    if(this.props.colorboard?.photos?.length > 0) {
      mediaData = await this.props.firebase.loadMediaDirect(this.props.colorboard.photos);
    } else if(this.props.colorboard?.images) {
      mediaData = await this.props.firebase.loadMedia(this.props.colorboard.images);
    }
    this.setState({mediaData, loading: false});
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.state.mediaData.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.state.mediaData.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {

    const { activeIndex, loading, mediaData } = this.state;

    const slides = mediaData.map((m) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={m.url}
        >
          <img src={m.url} alt={'na'} className="img-fluid" width="100%"/>
        </CarouselItem>
      );
    });
    
    return(
      <Container>
      {loading ? (<Loading />) : (
        <Carousel activeIndex={activeIndex} next={this.next} previous={this.previous}>
          <CarouselIndicators items={mediaData} activeIndex={activeIndex} onClickHandler={this.goToIndex}/>
          {slides}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
        </Carousel>
      )}
      </Container>
    )
  }

}

const Photos = compose(
  withFirebase,
)(PhotosBase)

export default Photos;