import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';

import {  Button, Row, Col, Card, CardImg, Carousel, CarouselItem, CardBody, CardFooter, CardTitle } from 'reactstrap';
import { GAEvent } from '../GA/tracker';

import {literals} from '../../_constants/literals'

import Loading from '../Loading';

const ScreenViewBase = (props) => {

  const [ loading, setLoading ] = useState(true);
  const [ thumbnailURL, setThumbnailURL ] = useState('');

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const fetchThumbnail = async() => {
    setLoading(true);
    if(props.cb.thumbnails && props.cb.thumbnails.length > 0) {
      let mediaData = await props.firebase.loadMediaDirect(props.cb.thumbnails);
      setThumbnailURL(mediaData);
    } else if(props.cb.thumbnail) {
      let mediaData = await props.firebase.loadMedia([props.cb.thumbnail]);
      if(mediaData[0] && mediaData[0].url) setThumbnailURL(mediaData[0].url);
    }
    setLoading(false);
    //let mediaData = await this.props.firebase.loadMedia(this.props.images);
  }

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === thumbnailURL.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? thumbnailURL.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const getSlides = () => {
    const slides = thumbnailURL.map((m) => {
      return (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={m.url}
        >
          <img src={m.url} alt={'none'} className="img-fluid" width="100%"/>
        </CarouselItem>

      )
    })
    return slides;
  }

  useEffect(() => {
    fetchThumbnail();
  }, [])


  return (
      <Card className="my-2 shadow">
        {loading ? <Loading /> :
          props.cb.thumbnails && props.cb.thumbnails.length > 0 ?
          <>
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              ride={"carousel"}
              interval={4000}
            >
              {getSlides()}
            </Carousel>
          </>        
          : props.cb.thumbnail && <CardImg top width="100%" src={thumbnailURL} alt={props.cb.address} /> }
        <CardBody className="pb-0">
          <CardTitle><h5>{props.cb.address} {props.cb.geolocation && <a href={"https://www.google.com/maps/place/" + props.cb.geolocation.latitude + "," + props.cb.geolocation.longitude} target="_blank" rel="noopener noreferrer"><i className="fas fa-map-marker-alt"></i></a>}</h5></CardTitle>
          <div className="screenDescription">
            {props.cb.description}
          </div>
          {props.cb.network && props.cb.network.logo && <img className="float-right" src={props.cb.network.logo} />}
        </CardBody>
        <hr />
        <CardBody className="pt-0">
            {props.cb.performance ? <div>
              <span className="text-primary"><i className="fas fa-fire" ></i></span> {props.cb.performance.value && props.cb.performance.value.toLocaleString()}
              <br /><small>{props.cb.performance.text}</small>
            </div> :
            <div>
              {props.cb.resolutionX < props.cb.resolutionY ? (<i className="fas fa-portrait"></i>) : (<i className="fas fa-image"></i>)}
              {" " + props.cb.resolutionX + " x " + props.cb.resolutionY + " px"}
            </div>
            }
        </CardBody>
      </Card>
  )
}

const ScreenView = compose(
  withFirebase,
)(ScreenViewBase)

export default ScreenView;