import React, { useState, useEffect } from 'react';

import { compose } from 'recompose';

import moment from 'moment';

import { withAuthorization } from '../_auth/Session';
import { withFirebase } from '../Firebase';

import {  Badge, Container, Button, Row, Col, ButtonGroup, CardTitle, Table } from 'reactstrap';

import Loading from '../Loading';

import Footer from '../Footer';

import Deals from './deals';

const MyBookingsBase = (props) => {

  const [ loadingBookings, setLoadingBookings ] = useState(false);

  const [ groupedBookings, setGroupedBookings ] = useState([]);

  const [ fil, setFil ] = useState(0);

  const getCBandMedia = async (uniqCBIds, uniqMedia) => {
    const colorboards = {};
    const media = {};

    await Promise.all(uniqCBIds.map(async(cb) => {
      const cData = (await props.firebase.colorboard(cb).get()).data();
      colorboards[cb] = {id: cb, address: cData.address};
    }));

    await Promise.all(uniqMedia.map(async(m)=> {
      const mData = (await props.firebase.db.doc(m).get()).data();
      const downloadLink = await props.firebase.storageRef().child(mData.fullPath).getDownloadURL();
      media[m] = {displayname: mData.displayname, downloadLink }
    }))
    
    return [colorboards, media];
  }

  const loadBookings = async() => {
    setLoadingBookings(true);

    let myBookings = [];
    const endFilter = new Date();
    endFilter.setMonth(endFilter.getMonth() - fil);
    const s1 = await props.firebase.db.collectionGroup('bookings').where('user', '==', '/users/' + props.uid).where('end', '>=', endFilter).get();
    s1.forEach(doc => {
      myBookings.push({id: doc.id, colorboard: doc.ref.parent.parent.id, ...doc.data()})
    })

    myBookings.sort((a,b) =>  new Date(a.start) - new Date(b.start));

    const uniqCBIds = [...new Set(myBookings.map(item => item.colorboard))];
    const uniqMedia = [...new Set(myBookings.map(item => item.media))];

    let colorboards, media;
    [colorboards, media] = await getCBandMedia(uniqCBIds, uniqMedia);

    myBookings = myBookings.map(b => {
      return {...b, colorboard: colorboards[b.colorboard], media: media[b.media]};
    });

    setGroupedBookings(myBookings);
    setLoadingBookings(false);
  }

  const updateFilter = (months) => {
    if(months === fil) {
      setFil(0);          // reset 
    } else {
      setFil(months);
    }
  }

  useEffect(() => {
    loadBookings();
  }, [fil])

  return (
    <>
      <Deals uid={props.uid} firebase={props.firebase} />
      {loadingBookings ? (<Loading />) : (
        <Container className="pageContent">
          <Row>
            <Col>
              <h5 className="pageTitle">Meine Screen Bookings</h5>
            </Col>
            <Col>
              <ButtonGroup className="float-right">
                  <Button color="link" className="btn-thin" outline={fil === 12}onClick={() => updateFilter(12)}><i className="fas fa-history"></i> 12 Monate</Button>
                  <Button color="link" className="btn-thin" outline={fil === 3} onClick={() => updateFilter(3)}><i className="fas fa-history"></i> 3 Monate</Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Table>
            <thead>
              <tr>
                <th>Status</th>
                <th>Screen</th>
                <th>Start</th>
                <th>Ende</th>
                <th>Media</th>
                <th>Preis</th>
              </tr>
            </thead>
            <tbody>
              {
                groupedBookings.map(booking => (
                  <tr key={booking.id}>
                    <td>{
                      booking.status === 'confirmed' ? (
                        <Badge color="success">bestätigt</Badge>
                      ) : (
                        booking.status === 'pending' ? (
                          <Badge color="warning">ausstehend</Badge>
                        ) : (
                          <Badge color="danger">abgelehnt</Badge>
                        )
                      )
                    }</td>
                    <td><a href={"/book/" + booking.colorboard.id} target="_blank" rel="noopener noreferrer">{booking.colorboard.address}</a></td>
                    <td>{String(moment(booking.start.toDate()).format('DD.MM.YYYY HH:mm:ss'))}</td>
                    <td>{String(moment(booking.end.toDate()).format('DD.MM.YYYY HH:mm:ss'))}</td>
                    <td><a href={booking.media.downloadLink} target="_blank" rel="noopener noreferrer">{booking.media.displayname}</a></td>
                    <td>{booking.status === 'declined' ? (<del>{booking.price.toFixed(2) + ' CHF'}</del>):(booking.price.toFixed(2) + ' CHF')}</td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Container>
      )}      
      <Footer/>
    </>
  )
}

const condition = authUser => !!authUser;

const MyBookingsPage = compose(
    withAuthorization(condition),
    withFirebase,
  )(MyBookingsBase)

export default MyBookingsPage;