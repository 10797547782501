import { atom } from "recoil";

export const colorboardsState = atom({
  key: "colorboardsState",
  default: [],
});

export const gettingStartedOpenState = atom({
  key: "gettingStartedOpenState",
  default: false,
});

export const mediaUploadOpenState = atom({
  key: "mediaUploadOpenState",
  default: false,
})

export const userIdState = atom({
  key: "userIdState",
  default: null,
});

export const userMediaState = atom({
  key: "userMediaState",
  default: false,
})