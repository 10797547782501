import React from 'react';
import { ClipLoader } from 'react-spinners';
import { Button, ModalBody, ModalFooter, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Col, FormGroup } from 'reactstrap';

const initialState = {
  firstname: '',
  lastname: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
  loading: false,
}

class Signup extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };
  }

  componentDidMount = () => {
    //console.log('login form mounted')
    
  }

  onSubmit = event => {
    event.preventDefault();

    this.setState({loading: true})
    const { firstname, lastname, email, passwordOne } = this.state;    
    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        return this.props.firebase.addUserCallable({firstname, lastname, email});
      })
      .then(() => {})
      .catch(error => {
        this.setState({ error, loading: false });
      });
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {

    const {
      firstname,
      lastname,
      email,
      passwordOne,
      passwordTwo,
      error,
      loading,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      firstname === '' ||
      lastname === '';


    return (
      <div>
        <ModalBody>
          <Form onSubmit={this.onSubmit}>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><i className="fas fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name="firstname"
                    id="firstname"
                    placeholder="Vorname"
                    value={firstname}
                    onChange={this.onChange}
                    autoFocus={true}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><i className="fas fa-user"></i></InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    name="lastname"
                    id="lastname"
                    placeholder="Nachname"
                    value={lastname}
                    onChange={this.onChange}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><i className="fas fa-envelope"></i></InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={email}
                    onChange={this.onChange}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><i className="fas fa-lock"></i></InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="password"
                    name="passwordOne"
                    id="passwordOne"
                    placeholder="Passwort"
                    value={passwordOne}
                    onChange={this.onChange}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><i className="fas fa-lock"></i></InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="password"
                    name="passwordTwo"
                    id="passwordTwo"
                    placeholder="Passwort wiederholen"
                    value={passwordTwo}
                    onChange={this.onChange}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              {loading ? <Button className="btn-block my-2" disabled={true}><ClipLoader sizeUnit={"px"} size={50} color={'#123abc'} loading={loading} /></Button>
                : isInvalid ? <Button className="btn-block my-2" color="secondary" disabled="true">Sign Up</Button> 
                : <Button className="btn-block my-2" color="primary">Sign Up</Button> }
              {error && <p>{error.message}</p>}
            </Col>
          </Form>
          </ModalBody>
          <ModalFooter>
            <p className="mr-auto">Bereits einen Account?<Button color="link" onClick={() => this.props.changeMode("login")}>Login</Button></p>
          </ModalFooter>
      </div>
    )
  }
}

export default Signup;