export const literals = {
  gallery: "Galerie",
  map: "Karte",
  book: "Buchen",
  addToCart: "In den Warenkorb",

  hour: "Stunde",
  minute: "Minute",
  second: "Sekunde",
  hours: "Stunden",
  minutes: "Minuten",
  seconds: "Sekunden",

  each: "à",
  landscape: "Querformat",
  portrait: "Hochformat",

  informationTitle: "Info - 1",
  informationSlotDuration: "Slotdauer",
  informationScreenTime: "Aufschaltungen pro Slot",
  performance: "Performance",

  informationDataSheet: "Datenblatt",

  selectMedia: "Medium auswählen",
  selectMediaTitle: "Medium auswählen - 2",
  selectMediaResolution: "Auflösung",
  selectMediaOrientation: "Orientierung",
  selectMediaRestrictions: "Einschränkungen",

  selectSlotTitle: "Slot auswählen - 3",
  selectSlotAvailable: "Verfügbare Slots",
  selectSlotSelected: "Ausgewählte Slots",
  selectSlotAll: "Alle auswählen",

  selectDealWeeks : "Wochen auswählen - 3"

}