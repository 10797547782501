import React, { useState, useEffect } from 'react';

import { css } from '@emotion/core';
import { BarLoader } from 'react-spinners';

const override = css`
    display: block;
    margin: 0 auto;
`;

const AwaitCheckout = (props) => {

  const getText = () => {
    if(props.status) {
      if(props.status === 'paying') {
        return "Die Zahlung wird ausgeführt";
      } else if (props.status === 'booking') {
        return "Die Buchung wird ausgeführt";
      }
    }
  }
  return (
    <>
      <p className="text-center">{getText()}</p>
      <BarLoader
        css={override}
        color={'#49c5b6'}
        loading={true}
        width={200}
        height={5}
      />
      <br />
      <p className="text-center">Falls diese Seite länger als 1 Minute angezeigt wird, kontaktiere uns unter <a href="mailto:feedback@biddad.com">feedback@biddad.com</a></p>
    </>
  )
}

export default AwaitCheckout;