import React, { useState, useEffect } from 'react';

import { Alert, Button, Card, CardHeader, CardBody, CardFooter, Table } from 'reactstrap';

import { withFirebase } from '../Firebase';
import { GAEvent } from '../GA/tracker';

import { useRecoilState } from "recoil";
import { userMediaState } from "../_recoil/atoms.js";

import moment from 'moment';

import Loading from '../Loading';

const Media = (props) => {

  const [ userMedia, setUserMedia ] = useRecoilState(userMediaState);
  const [ loading, setLoading ] = useState(false);

  const loadMedia = async () => {
    setLoading(true);
    if (!(props.media === undefined || props.media == 0)) {
      if(!userMedia) {
        let mediaData = await props.firebase.loadMedia(props.media);
        setUserMedia(mediaData);
      }
    }
    setLoading(false);
  }

  const formatBytes = (a,b) => {if(0==a)return"0 Bytes";var c=1024,d=b||2,e=["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"],f=Math.floor(Math.log(a)/Math.log(c));return parseFloat((a/Math.pow(c,f)).toFixed(d))+" "+e[f]}

  const removeMedia = (mid) => {
    GAEvent('AccountMedia', 'media removed');
    let newUserMedia = userMedia;
    newUserMedia = newUserMedia.filter(m => {
      if(mid !== m.id) return m;
    })
    setUserMedia(newUserMedia);

    // remove it as well on firebase 
    props.firebase.deleteMediaCallable({mid});
  }

  const viewMedia = () => {
    GAEvent('AccountMedia', 'media viewed');
  }

  useEffect(() => {
    loadMedia();
  }, [])

  return (
      <Card className="my-2 shadow">  
        <CardBody>
          <div className="d-flex">
            <h5 className="flex-grow-1"><i className="fas fa-photo-video" /> Medien</h5>
          </div>  
          <div className="mt-2">
          {loading ? (<Loading />) : (
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Dateityp</th>
                  <th>Auflösung</th>
                  <th>Hinzugefügt am</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                { userMedia && userMedia.map((mD) => (
                  <tr key={mD.id}>
                    <td><a href={mD.url} target="_blank"  rel="noopener noreferrer" onClick={() => viewMedia()}>{mD.displayname}</a></td>
                    <td>{mD.fileType}</td>
                    {/*<td>{this.formatBytes(mD.size)}</td>*/}
                    <td>{mD.dimensions && (mD.dimensions.width < mD.dimensions.height ? (<i className="fas fa-portrait" />) : (<i className="fas fa-image" />))}
                        {mD.dimensions && (" " + mD.dimensions.width + ' x ' + mD.dimensions.height + ' px')}</td>
                    <td>{mD.timeCreated && String(moment(mD.timeCreated.toDate()).format('DD.MM.YYYY HH:mm'))}</td>
                    <td><Button className="float-right fas fa-trash-alt" onClick={() => removeMedia(mD.id)}></Button></td>
                  </tr>
                ))
                }
              </tbody>
            </Table>)}
            <small className="float-right"><i className="fas fa-info" /> Medien können direkt im Buchungsprozess hinzugefügt werden.</small>
          </div>
        </CardBody>
      </Card> 
  )
}

export default withFirebase(Media);