import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { uuid } from 'uuidv4';

import { withAuthorization } from '../_auth/Session';

import Cropper from 'react-easy-crop';
import { getOrientation } from 'get-orientation/browser'

import "video-react/dist/video-react.css"; // import css
import { Player } from 'video-react';

import { useRecoilState } from "recoil";
import { mediaUploadOpenState, userMediaState } from "../_recoil/atoms.js";

import { readFile, getRotatedImage, getCroppedImg, resizeImage } from './_helpers.js';

import { Progress, Button, Modal, ModalHeader, ModalBody, Col, Row, FormGroup, Label, Input } from 'reactstrap';

import { GAEvent } from '../GA/tracker';

const ORIENTATION_TO_ANGLE = {
  '3': 180,
  '6': 90,
  '8': -90,
}

const MediaUploadBase = (props) => {

  const [ mediaUploadOpen, setMediaUploadOpen ] = useRecoilState(mediaUploadOpenState);
  const [ userMedia, setUserMedia ] = useRecoilState(userMediaState);

  const [ imgSrc, setImgSrc ] = useState();
  const [ imgCrop, setImgCrop ] = useState({x: 0, y: 0})
  const [ imgCroppedAreaPixels, setImgCroppedAreaPixels ] = useState();
  
  const [ uploadProgress, setUploadProgress ] = useState(0);
  const [ isUploading, setIsUploading ] = useState(false);

  const [ fileName, setFileName ] = useState("");
  const [ fileType, setFileType ] = useState("");

  const isClient = typeof window === 'object';

  const getSize = () => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  const onFileChange = async(e) => {
    GAEvent('MediaUpload', 'NewFileSelected');
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file);

      setFileType(file.type);
      
      if (file.type.includes('image')) {
        // apply rotation if needed
        const orientation = await getOrientation(file);
        const rotation = ORIENTATION_TO_ANGLE[orientation]
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
        }
        imageDataUrl = await resizeImage(imageDataUrl, props.resolution.width, props.resolution.height)
        setImgCrop({x: 0, y: 0})
      }
      setFileName(file.name)
      setImgSrc(imageDataUrl);
      setIsUploading(false);
      setUploadProgress(0);
    }
  }

  const onCropChange = crop => {
    setImgCrop(crop)
  }

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setImgCroppedAreaPixels(croppedAreaPixels)
  }

  const startUpload = async() => {
    try {
      setIsUploading(true);
      setUploadProgress(0);

      let resMedia = "";
      if(fileType.includes('image')) {
        const croppedImage = await getCroppedImg(
          imgSrc,
          imgCroppedAreaPixels
        )
        resMedia = croppedImage
      } else {
        resMedia = imgSrc;
      }


      const storagePath = 'userUploads/' + uuid() + '.' + fileType.split("/")[1];
      GAEvent('MediaUpload', 'StartUpload', storagePath);
      let uploadTask = props.firebase.storageRef().child(storagePath).putString(resMedia, 'data_url');

      uploadTask.on('state_changed', (snapshot) => {
        setUploadProgress(80 * snapshot.bytesTransferred / snapshot.totalBytes);
      }, (err) => {
        console.log('err while uploading')
        setImgSrc("");
        setImgCrop({x: 0, y: 0});
        setImgCroppedAreaPixels(null);
        setUploadProgress(0);
        setIsUploading(false);
        setFileName("");
        setFileType("");
        setMediaUploadOpen(false);
      }, async(snap) => {
        console.log('file uploaded')
        let dimensions = null;
        if(fileType.includes('image')) {
          dimensions = {width: props.resolution.width, height: props.resolution.height};
        }
        const mid = await props.firebase.addMediaNewCallable({doc: {bucket: process.env.REACT_APP_STORAGE_BUCKET,
                                            displayname: fileName,
                                            fullPath: uploadTask.snapshot.ref.fullPath,
                                            fileType: fileType,
                                            dimensions,
                                            }})

        setUploadProgress(90);

        const userRef = await props.firebase.user(props.authUser.uid).get();
        const userDoc = userRef.data();
        const mData = await props.firebase.loadMedia(userDoc.media);
        setUserMedia(mData);
        props.setSelectedMedia('/media/' + mid.data)

        setImgSrc("");
        setImgCrop({x: 0, y: 0});
        setImgCroppedAreaPixels(null);
        setUploadProgress(0);
        setIsUploading(false);
        setFileName("");
        setFileType("");
        setMediaUploadOpen(false);
      })

      // setIsFinished(true)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (!isClient) {
      return false;
    }
    function handleResize() {
      setWindowSize(getSize());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return (
    <Modal isOpen={mediaUploadOpen} className="mw-100 w-75" >
      <ModalHeader toggle={!isUploading && (() => setMediaUploadOpen(false))}>
        Neues Medium hochladen
      </ModalHeader>
      <ModalBody>
        <p><a href="https://www.biddad.com/wp-content/uploads/2021/02/Visualisierung-BiddAD.pdf" target="_blank" rel="noopener noreferrer">Was muss ich beim Erstellen des Mediums beachten?</a></p>
        { isUploading ? <Progress value={uploadProgress} />: 
        <>
          {props.image && props.video ? 
          <div>
            <input type="file" onChange={onFileChange}
              multiple={false}
              accept={"image/png, image/jpeg, video/mp4"}
              />
            <em>Dateitypen: .png, .jpg, .mp4</em>
          </div> : props.image ?
          <div>
            <input type="file" onChange={onFileChange}
              multiple={false}
              accept={"image/png, image/jpeg"}
              />
            <em>Dateitypen: .png, .jpg</em>
          </div> :
          <div>
            <input type="file" onChange={onFileChange}
              multiple={false}
              accept={"video/mp4"}
              />
            <em>Dateitypen: .mp4</em>
          </div> 
          }
          { imgSrc && 
          <div>
            <div className="position-relative" style={{minHeight: windowSize.height*0.8}}>
              { fileType.includes('image') ? 
              <Cropper style={{border: "1px solid red"}}
                image={imgSrc}
                crop={imgCrop}
                minZoom={0.1}
                maxZoom={10}
                zoomWithScroll={false}
                aspect={props.resolution.width/props.resolution.height}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
              /> : <Player className="img-fluid" style={{maxHeight: windowSize.height*0.8}}>
              <source src={imgSrc} />
            </Player> }
            </div>
            <br />
            <div className="d-flex">
              <Label className="d-flex align-items-center" for="filename">Name:</Label>
              <Input className="flex-grow-1 mx-2" name="filename" id="filename" value={fileName} onChange={e => setFileName(e.target.value)} />
              <Button color="primary" onClick={startUpload}>Upload</Button>
            </div>            
          </div>}
        </>}
      </ModalBody>
    </Modal>
  )
}

const condition = authUser => !!authUser;

const MediaUpload = compose(
  withAuthorization(condition),
  withFirebase,
)(MediaUploadBase)

export default MediaUpload;


// <img src={croppedImage}></img>


/* 
<div class="row">
    <div class="col-md-12">
      <form class="form-inline" action="/search" accept-charset="UTF-8" method="get">
        <div class="flex-fill mr-2">
          <input type="search" name="search" id="search" value="" placeholder="Full-width search form with separate button w/ wrapper div" class="form-control w-100" aria-label="Search this site"/>
        </div>
        <input type="submit" name="commit" value="Search" class="btn btn-primary" data-disable-with="Search"/>
      </form>
    </div>
  </div>
  */


  //            <MediaUpload resolution={{width: 1080, height: 1920}} image={true} video={true} />