import React, { useState, useEffect } from 'react';

import {  Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';

import Loading from '../Loading';

import moment from 'moment';
import { Player } from 'video-react';

const ReviewModal = (props) => {

  const isClient = typeof window === 'object';
  const getSize = () => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }
  const [windowSize, setWindowSize] = useState(getSize);

  const [ open, setOpen ] = useState(true);

  const [ groupedBookings, setGroupedBookings ] = useState();
  const [ triplets, setTriplets ] = useState();  // cbid, media, user 

  const [ media, setMedia ] = useState({});

  const [ activeIndex, setActiveIndex ] = useState(0);

  const [ loading, setLoading ] = useState(false);

  const groupBookings = () => {
    const gB = {};
    const tripl = [];
    const m = [];
    if(props.upcomingPending) {
      props.upcomingPending.forEach(b => {
        m.push(b.media);
        if(gB[[b.cbid, b.media, b.user]]) {
          gB[[b.cbid, b.media, b.user]].push({id: b.id, start: b.start, end: b.end, price: b.price});
        } else {
          gB[[b.cbid, b.media, b.user]] = [{id: b.id, start: b.start, end: b.end, price: b.price}];
          tripl.push([b.cbid, b.media, b.user]);
        }
      })
    }
    fetchMedia(m)
    setGroupedBookings(gB);
    setTriplets(tripl);
  }

  const getColorboardAddress = (cbid) => {
    if(props.colorboards) {
      let obj = props.colorboards.find(o => o.id === cbid);
      if(obj && obj.address) {
        return obj.address
      }
    }
  }

  const fetchMedia = async(mpar) => {
    const med = {};
    const uniqMedia = mpar.filter((value, index, self) => self.indexOf(value) === index);
    await Promise.all(uniqMedia.map(async(m)=> {
      const mData = (await props.firebase.db.doc(m).get()).data();
      const downloadLink = await props.firebase.storageRef().child(mData.fullPath).getDownloadURL();
      med[m] = {displayname: mData.displayname, fileType: mData.fileType, downloadLink }
    }))
    setMedia(med);
  }

  const confirm = async() => {
    setLoading(true);
    const bids = groupedBookings[triplets[activeIndex]].map(b => b.id)
    await props.firebase.reviewBookingCallable({cid: triplets[activeIndex][0], bids, method: 'confirm'});
    await props.getUpcomingBookings();
    setLoading(false);
  }

  const decline = async() => {
    setLoading(true);
    const bids = groupedBookings[triplets[activeIndex]].map(b => b.id)
    await props.firebase.reviewBookingCallable({cid: triplets[activeIndex][0], bids, method: 'decline'});
    await props.getUpcomingBookings();
    setLoading(false);
  }

  useEffect(() => {
    groupBookings();
  }, [])

  useEffect(() => {
    if (!isClient) {
      return false;
    }
    function handleResize() {
      setWindowSize(getSize());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return(
    <Modal isOpen={props.isOpen} size="xl">
      <ModalHeader toggle={() => props.setIsOpen(false)}>Review Bookings</ModalHeader>
      {triplets && triplets.length > 0 && groupedBookings[triplets[activeIndex]] ? 
        <> 
          <ModalBody>
            <Row>
              <Col>
                {media && media[triplets[activeIndex][1]] && media[triplets[activeIndex][1]].fileType === 'video/mp4' ? (
                  <Player className="img-fluid" style={{maxHeight: windowSize.height/2}}>
                    <source src={media[triplets[activeIndex][1]].downloadLink} />
                  </Player>
                ) : media && media[triplets[activeIndex][1]] && (
                  <img id="media" className="img-fluid" style={{maxHeight: windowSize.height/2}} src={media && media[triplets[activeIndex][1]].downloadLink}></img>
                )}
              </Col>
              <Col>
                <p>Screen: {getColorboardAddress(triplets[activeIndex][0])}</p>
                <p>Media: {media && media[triplets[activeIndex][1]] && (
                  <a href={media[triplets[activeIndex][1]].downloadLink} target="_blank" rel="noopener noreferrer">{media[triplets[activeIndex][1]].displayname}</a>
                )}</p>
                <p>user: {triplets[activeIndex][2]}</p>
                <p>Anzahl Slots: {groupedBookings[triplets[activeIndex]].length}</p>
                <p>Slots:</p>
                <div className="overflow-auto" style={{"maxHeight" : "500px"}}>
                {groupedBookings[triplets[activeIndex]].map(b => (
                  <p key={b.id}>{String(moment(b.start.toDate()).format('DD.MM.YYYY HH:mm'))} - {String(moment(b.end.toDate()).format('DD.MM.YYYY HH:mm'))}</p>
                ))}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-block">
            {loading ? <Loading /> :
              <Row>
                <Col>
                  <Button color="danger" outline onClick={() => decline()}>Ablehnen</Button>
                </Col>
                <Col>
                  <div>
                    <Button color="link" className="btn-thin" disabled={activeIndex===0} onClick={() => setActiveIndex(activeIndex-1)}><i className="fas fa-chevron-left"></i></Button>
                    <span>{activeIndex+1}/{triplets && triplets.length}</span>
                    <Button color="link" className="btn-thin" disabled={!triplets || activeIndex >= triplets.length-1} onClick={() => setActiveIndex(activeIndex+1)}><i className="fas fa-chevron-right"></i></Button>
                  </div>
                </Col>
                <Col>
                  <Button color="success" className="float-right" outline onClick={() => confirm()}>Bestätigen</Button>
                </Col>
              </Row>
            }
          </ModalFooter>
        </>
        :
        <ModalBody>Alle Buchungen wurden überprüft!</ModalBody>
      }

    </Modal>
  )
}

export default ReviewModal;