import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
// import { Link } from "react-router-dom";
import { compose } from 'recompose';
import  moment from 'moment';
import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization } from '../_auth/Session';

import Moment from 'react-moment';

import {literals} from '../../_constants/literals'

import MediaSelector from '../Book2020/MediaSelector';

import ScreenView from './ScreenView';

import {  Alert, Button, Row, Col, Container, Card, CardBody, Table, Tooltip } from 'reactstrap';
import { GAEvent } from '../GA/tracker';

import CartErrorModal from '../Cart/cartErrorModal';
import CartAddedModal from '../Cart/cartAddedModal';

import * as ROUTES from '../../_constants/routes';

import Loading from '../Loading';

const BookDealBase = (props) => {

  const [ loading, setLoading ] = useState(true);

  const [ selectedMedia, setSelectedMedia ] = useState();

  const [ monthFilter, setMonthFilter ] = useState(new Date());

  const [ cbs, setCbs ] = useState([]);

  const [ user, setUser ] = useState();

  const [ deal, setDeal ] = useState();

  const [ slots, setSlots ] = useState([]);

  const [ nrSlotsSelected, setNrSlotsSelected ] = useState(0);

  const [ cartError, setCartError ] = useState(false);
  const [ cartSuccess, setCartSuccess ] = useState(false);

  const [ toolTipBook, setToolTipBook ] = useState(false);

  const loadFirebaseData = async() => {
    const snap = await props.firebase.deal(props.match.params.did).get();
    const d = snap.data();

    setUser((await props.firebase.user(props.authUser.uid).get()).data())

    let cbs = [];
    let logos = [];
    await Promise.all(d.colorboards.map(async(c) => {
      let cb = (await c.get()).data();
      if(cb.network && cb.network.logo && cb.network.name) {
        logos.push(cb.network.logo)
      }
      if(cb.datasheet) {
        try {
          const downloadLink = await props.firebase.storageRef().child(cb.datasheet).getDownloadURL();
          cb = {...cb, datasheet: downloadLink}
        } catch (err) {
          console.log('error fetching datasheet pdf')
        }
      }
      cbs.push(cb)
    }))
    // fetch logos
    const fetchedLogos = {};
    logos = logos.filter((value,index,self) => self.indexOf(value) === index);
    for(const logo of logos) {
      try {
        let downloadLink = await props.firebase.storageRef().child(logo).getDownloadURL();
        fetchedLogos[logo] = downloadLink;
      } catch {
        fetchedLogos[logo] = null;
      }
    }
    // add logo to cb
    cbs.forEach(cb => {
      if(cb.network && cb.network.logo) {
        cb.network.logo = fetchedLogos[cb.network.logo];
        return cb;
      }
    })

    setCbs(cbs);

    setDeal(d);
    setLoading(false);
  }

  const createSlots = () => {
    const s = [];
    if(deal && deal.start && deal.end) {
      let counter = new Date(deal.start.toDate());
      while(counter < deal.end.toDate()) {
        const end = new Date(counter);
        end.setDate(end.getDate() + 6);
        let visible = false;
        const start = new Date(counter);
        if(start.getMonth() === monthFilter.getMonth() && start.getFullYear() === monthFilter.getFullYear() && start >= new Date()) visible = true;
        s.push({start, end: new Date(end), visible, selected: false})
        counter.setDate(counter.getDate()+7);
      }
      setSlots(s)
    }
  }

  const updateVisibleSlots = (value) => {
    let newSlots = slots;
    const newMonth = new Date(monthFilter);
    newMonth.setMonth(newMonth.getMonth(newMonth) + value);
    newSlots.map(s => {
      if(newMonth.getMonth() === s.start.getMonth() && newMonth.getFullYear() === s.start.getFullYear() && s.start >= new Date()) {
        s.visible = true;
      } else {
        s.visible=false;
      }
      return s;
    })
    //newSlots = newSlots.sort((a,b) => a.start > b.start ? 1 : a.start < b.start ? -1 : 0);
    setSlots(newSlots);
    setMonthFilter(newMonth);
  }

  const toggleSlot = (index) => {
    const newSlots = slots;
    newSlots[index] = {...newSlots[index], selected: !newSlots[index].selected};
    if(newSlots[index].selected) {
      GAEvent('BookDeal', 'DealSlotSelected')
    } else {
      GAEvent('BookDeal', 'DealSlotDeselected')
    }
    setSlots([...newSlots]);
    setNrSlotsSelected(newSlots.filter(s => s.selected).length);
  }

  const addToCart = async() => {
    setLoading(true);
    const selectedSlots = [];
    slots.map(s => {s.selected && selectedSlots.push(s)});
    
    const cartRef = props.firebase.db.collection('carts').doc(props.authUser.uid);
    const cartDoc = await cartRef.get();
    const cartData = cartDoc.data();
    const dealRef = props.firebase.db.collection('deals').doc(props.match.params.did);
    if(cartDoc.exists && cartData.status) {
      setCartError(true);
      setLoading(false);
    } else {
      if(cartDoc.exists && cartData.createdAt) {
        // user has an existing cart
        await cartRef.update({
          deals: props.firebase.FieldValue.arrayUnion({
            media: selectedMedia,
            deal: dealRef,
            slots: selectedSlots,
            total: deal.priceDiscount,
            addedAt: new Date(),
          }),
        });
      } else {
        // use has no cart or an empty cart
        await cartRef.set({createdAt: new Date(), 
          deals: [{
            media: selectedMedia,
            deal: dealRef,
            slots: selectedSlots,
            total: deal.priceDiscount,
            addedAt: new Date(),
          }]
        })
      }
      setCartSuccess(true);
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    loadFirebaseData();
  }, [])

  useEffect(() => {
    createSlots();
  }, [deal])


  return (
    <>
    {loading ? (<Loading />) : deal && (
    <>
    <Container>
    <h4 className="mt-3">{deal.name}</h4>
      <Card className="my-2 shadow">
        <CardBody>
          <div className="d-flex">
            <h5 className="text-primary flex-grow-1"><i className="fas fa-info"></i> {literals.informationTitle}</h5>
            <h5 className="text-primary float-right"><i className="fas fa-check"></i></h5>
          </div> 
          <br />
          <Table>
            <tbody>
              <tr>
                <td>Dauer</td>
                <td className="text-secondary">{deal.durationDays/7} Wochen</td>
              </tr>
              <tr>
                <td>Preis</td>
                <td className="text-secondary"><strike>{deal.price.toFixed(2) + ' CHF'}</strike> {deal.priceDiscount.toFixed(2) + ' CHF'}</td>
              </tr>
              <tr>
                <td>Anzeige-Zeit pro Screen</td>
                <td className="text-secondary">{deal.description}</td>
              </tr>
              {cbs && cbs.length > 0 && 
              <tr>
                <td>{literals.informationDataSheet}</td>
                <td className="text-secondary">
                {cbs.map(cb => 
                  <div key={cb.address}><a href={cb.datasheet} target="_blank" rel="noopener noreferrer"><i className="fas fa-file-pdf"></i> {cb.address}</a><br/></div>
                )}
                </td>
              </tr>
              }
            </tbody>
          </Table>
          <p>Screens in diesem Deal:</p>
          <Row>
            {cbs && cbs.length > 0 && cbs.map(cb =>  <Col key={cb.address} lg="3" sm="6"><ScreenView cb={cb} key={cb.id} /></Col>)}
          </Row>
        </CardBody>
      </Card>
      <Card className="my-2 shadow">  
          <CardBody>
            <div className="d-flex">
              <h5 className={selectedMedia ? "text-primary flex-grow-1" : "text-secondary flex-grow-1"}><i className="fas fa-photo-video"></i> {literals.selectMediaTitle}</h5>
              <h5 className="float-right">{selectedMedia && <i className="fas fa-check text-primary"/>}</h5>
            </div> 
            <div className="mt-2">
              <MediaSelector selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia} user={user} template={deal.template} screenDimensions={{x: deal.resolutionX, y: deal.resolutionY}} restrictions={{image: true, video:false, text: "Keine Videos oder Animationen"}}/>
            </div>
        </CardBody>
      </Card> 
      <Card className="my-2 shadow">  
        <CardBody>
          <div className="d-flex">
            <h5 className={nrSlotsSelected == deal.durationDays/7 ? "text-primary flex-grow-1": "text-secondary flex-grow-1"}><i className="fas fa-calendar-alt"></i> {literals.selectDealWeeks}</h5>
            <h5 className="float-right">{nrSlotsSelected == deal.durationDays/7 && <i className="fas fa-check text-primary"/>}</h5>
          </div> 
          <p>Die {deal.durationDays/7} Wochen können einzeln oder aneinanderhängend gebucht werden.</p>
          <Card>
            <CardBody className="py-2">
                <div className="text-center">
                  <Button color="link" outline onClick={() => updateVisibleSlots(-1)}><i className="fas fa-chevron-left"></i></Button>
                  <Moment color="primary" format="MMMM, YYYY">{monthFilter}</Moment>
                  <Button color="link" outline onClick={() => updateVisibleSlots(+1)}><i className="fas fa-chevron-right"></i></Button>
                </div>
            </CardBody>
          </Card>
          <Card className="p-2">
            <CardBody>
              <div className="overflow-auto" style={{"maxHeight" : "300px"}}>
                {nrSlotsSelected < deal.durationDays/7 ? 
                slots.map((s, index) =>
                  s.visible && <Button style={{width: "250px"}} key={s.start} className="btn-round m-1" color="primary" size="sm" onClick={() => toggleSlot(index)} outline={!s.selected}>
                  {String(moment(s.start).format('DD.MM.YYYY')) + ' - ' + String(moment(s.end).format('DD.MM.YYYY'))}
                  </Button>                
                ) : (
                <div><Alert color="success">Sie haben die Anzahl Wochen in diesem Deal ausgewählt. Klicken Sie auf Buchen!</Alert></div>
                )}
              </div>          
            </CardBody>
          </Card>
          <br />
          <div>
            <small>{literals.selectSlotSelected}</small>
            <Card className="p-2">
              <CardBody>
                <div className="overflow-auto" style={{"maxHeight" : "300px"}}>
                  {slots.map((s, index) => 
                    s.selected && <Button style={{width: "250px"}} key={s.start} className="btn-round m-1" color="primary" size="sm" onClick={() => toggleSlot(index)}>
                    {String(moment(s.start).format('DD.MM.YYYY')) + ' - ' + String(moment(s.end).format('DD.MM.YYYY'))}
                    </Button>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </CardBody>
      </Card>
    </Container>
    <br /><br /><br />
    <Row className="fixed-bottom bg-light p-3">
      <Col className="align-self-center">
        <span className="align-middle">Total: {deal.priceDiscount.toFixed(2)} CHF</span> 
      </Col>
      <Col className="align-self-center d-none d-md-block" md="auto">
        <span>
          <i className="fas fa-lg fa-info text-primary"/>
          <svg height="3" width="150"><line x1="5" y1="0" x2="140" y2="0" stroke={selectedMedia ? "#49c5b6" : "#8c979e"} strokeWidth="3"></line></svg>
          <i className={selectedMedia ? "fas fa-lg fa-photo-video text-primary" : "fas fa-lg fa-photo-video text-secondary"}/>
          <svg height="3" width="150"><line x1="5" y1="0" x2="140" y2="0" stroke={slots.findIndex(obj => obj.selected) !== -1 ? "#49c5b6" : "#8c979e"} strokeWidth="3"></line></svg>
          <i className={nrSlotsSelected == deal.durationDays/7 ? "fas fa-lg fa-calendar-alt text-primary" : "fas fa-lg fa-calendar-alt text-secondary"} />
        </span>
      </Col>
      <Col className="align-self-end">
        {nrSlotsSelected === deal.durationDays/7 && selectedMedia ? (
          <Button block color="primary" onClick={() => {GAEvent('BookDeal', 'AddToCart'); addToCart()}}><i className="fas fa-shopping-cart"></i> {literals.addToCart}</Button>
            ) : (
          <div className="float-right">
            <Button id="ttBook" color="secondary" onClick={() => GAEvent('BookDeal', 'DisabledShowConfirmBookings')}><i className="fas fa-shopping-cart"></i> {literals.addToCart}</Button>
            <Tooltip placement="top" isOpen={toolTipBook} target="ttBook" toggle={() => setToolTipBook(!toolTipBook)}>
              Um die Buchung abzuschliessen, müssen Sie ein Medium und die Anzahl Wochen in diesem Deal auswählen.
            </Tooltip>
          </div>
        )}
      </Col>
    </Row>
    <CartErrorModal isOpen={cartError} />
    <CartAddedModal isOpen={cartSuccess} />
    </>
    )}
  </>
  )
}

const condition = authUser => !!authUser;

const BookDeal = compose(
  withAuthorization(condition),
  withFirebase,
  withRouter,
)(BookDealBase)


export default BookDeal;