import React, { useState, useEffect, useCallback } from 'react';

import { compose } from 'recompose';
// import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';

import { GoogleMap, LoadScript, Marker, InfoWindow} from '@react-google-maps/api';

import { Button } from 'reactstrap';

import ScreenCard from './ScreenCard';

import { GAEvent } from '../GA/tracker';

import {mapStyles} from '../../_constants/mapStyles';

import {literals} from '../../_constants/literals'

const initialState = {
  showingInfoWindow: false,
  activeMarker: null,
  selectedColorboard: null,
}

const containerStyle = {
  position: 'absolute',  
  width: '100%',
  height: '85%'
}


const MapContainer = (props) => {

  const [ activeMarker, setActiveMarker ] = useState(null);
  const [ selectedColorboard, setSelectedColorboard ] = useState(null);

  const onMarkerClick = (cb) => {
    // setShowingInfoWindow(true);
    // setSelectedColorboard(marker.cb);

    // GAEvent('CBMap', marker.cb.id + ' selected');
    setSelectedColorboard(cb)
  }

  // const onMapClick = (props) => {
  //   if (showingInfoWindow) {
  //     setShowingInfoWindow(false);
  //     setActiveMarker(null);
  //   }
  // }


  useEffect(() => {
    console.log(props.colorboards)
  }, [])


  return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{lat: 47.4, lng: 9}}
        options={{styles: mapStyles}}
        zoom={10}
      >
        { props.colorboards && (
            props.colorboards.map(cb => (
            (cb.geolocation && 
                <Marker
                cb = { cb }
                key = { cb.id }
                onClick = {() => onMarkerClick(cb)}
                title = { cb.address }
                position = {{ lat: cb.geolocation.latitude, lng: cb.geolocation.longitude }}
                name = { cb.address }
                icon={{
                  url: "/map-pin-32.png"
                  }}
                >
                  {selectedColorboard && selectedColorboard.id === cb.id &&
                  <InfoWindow onCloseClick={() => setSelectedColorboard(null)}>
                    <div>
                        <h6>{selectedColorboard.address}</h6>
                        <hr />
                        <div>
                        <Button block color="primary" href={"/book/" + selectedColorboard.id} onClick={() => GAEvent('ScreenMap', 'Book')}>{literals.book}</Button>
                        <hr />
                        </div>        
                    </div>
                  </InfoWindow>
                  }     
                </Marker>
            )
            ))
        )}

      </GoogleMap>
      // <Map
      // google={props.google}
      // styles={mapStyles}
      // containerStyle={containerStyle}
      // onClick={onMapClick}
      // zoom = { 10 }
      // initialCenter={{
      // lat: 47.4,
      // lng: 9,
      // }}>
      // { props.colorboards && (
      //     props.colorboards.map(cb => (
      //     (cb.geolocation && 
      //         <Marker
      //         cb = { cb }
      //         key = { cb.id }
      //         onClick = { onMarkerClick }
      //         title = { cb.address }
      //         position = {{ lat: cb.geolocation.latitude, lng: cb.geolocation.longitude }}
      //         name = { cb.address }
      //         icon={{
      //           url: "/map-pin.png",
      //           anchor: new props.google.maps.Point(16,16),
      //           scaledSize: new props.google.maps.Size(32,32)
      //           }}
      //         />
      //     )
      //     ))
      // )}
      //     <InfoWindow
      //     marker = { activeMarker }
      //     visible = { showingInfoWindow }>
      //       <div>
      //           {selectedColorboard && (
      //           <div>
      //               <h6>{selectedColorboard.address}</h6>
      //               <hr />
      //               <div>
      //               <Button block color="primary" href={"/book/" + selectedColorboard.id} onClick={() => GAEvent('ScreenMap', 'Book')}>{literals.book}</Button>
      //               <hr />
      //               </div>        
      //           </div>
      //           // <ScreenCard cb={selectedColorboard} literals={literals} />
      //           )}
      //       </div>
      //     </InfoWindow>
      // </Map>      
    );
}

// const MapContainer = compose(
//   GoogleApiWrapper({apiKey: process.env.REACT_APP_MAPS_API_KEY}),
// )(MapContainerBase);

export default MapContainer;