import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { Button, Row, Col, Card, CardBody } from 'reactstrap';

import Loading from '../Loading';

const Deal = (props) => {

  const [ dealData, setDealData ] = useState();
  const [ media, setMedia ] = useState({displayname: null, downloadLink: null});
  const [ removing, setRemoving ] = useState(false);

  const loadDeal = async() => {
    if(props.deal?.deal) {
      const d = await props.deal.deal.get();
      setDealData(d.data());
    }
  }

  const loadMedia = async() => {
    const mData = (await props.firebase.db.doc(props.deal?.media).get()).data();
    const downloadLink = await props.firebase.storageRef().child(mData.fullPath).getDownloadURL();
    setMedia({displayname: mData.displayname, downloadLink});
  }

  const deletePosition = async() => {
    setRemoving(true);
    await props.firebase.db.collection('carts').doc(props.uid).update({
      deals: props.firebase.FieldValue.arrayRemove(props.deal)
    })
    setRemoving(false);
  }

  useEffect(() => {
    loadDeal();
    loadMedia();
  }, []);

  return(
    <Card className="my-2 shadow">  
      <CardBody>
        <Row>
          <Col>
            Deal: <a href={"/deal/" + props.deal?.deal?.id}>{dealData?.name}</a>
          </Col>
          <Col>
          {props.editable && (removing ? <Loading /> : <Button color="link" className="btn-thin float-right" onClick={deletePosition}><i className="fas fa-trash-alt"></i></Button>)}
          </Col>
        </Row>
        <Row>
          <Col>
            Medium: {media.displayname && media.downloadLink && <a href={media.downloadLink} target="_blank" rel="noopener noreferrer">{media.displayname}</a>}
          </Col>
          <Col>
            <span className="float-right">{props.deal.total.toFixed(2)} CHF</span>
          </Col>
        </Row>
        <hr />
        <div className="d-flex flex-wrap pb-2">
          {props.deal.slots && props.deal.slots.map((s,index) => 
            <div className="shadow-sm p-2 m-1 border rounded" key={index}>{String(moment(s.start.toDate()).format('DD.MM.YYYY'))} - {String(moment(s.end.toDate()).format('DD.MM.YYYY'))}</div>
          )}
        </div>
      </CardBody>
    </Card>
  )

}

export default Deal;