import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import { GASet, GAEvent } from '../GA/tracker';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.app().functions('europe-west1');
    this.storage = app.storage();

    this.FieldValue = app.firestore.FieldValue;

    // *** Callable functions *** 
    // this.getSlotsCallable = this.functions.httpsCallable('getSlotsCallable');
    // this.addBookingCallable = this.functions.httpsCallable('addBookingCallable');
    
    // this.getBookingsForUserCallable = this.functions.httpsCallable('getBookingsForUser');
    // this.getBookingsForUserNew = this.functions.httpsCallable('getBookingsForUserNew');

    // this.addMediaCallable = this.functions.httpsCallable('addMediaCallable');
    this.addMediaNewCallable = this.functions.httpsCallable('addMediaNewCallable');
    this.deleteMediaCallable = this.functions.httpsCallable('deleteMediaCallable');

    this.addUserCallable = this.functions.httpsCallable('addUserCallable');

    // this.getBookingsForColorboardCallable = this.functions.httpsCallable('getBookingsForColorboardCallable');
    
    this.getBookingsForColorboardNewCallable = this.functions.httpsCallable('getBookingsForColorboardNewCallable');
    this.sendFeedbackCallable = this.functions.httpsCallable('sendFeedbackCallable');
    
    // this.bookDealCallable = this.functions.httpsCallable('bookDealCallable');
    // this.bookSlotsCallable = this.functions.httpsCallable('bookSlotsCallable');

    this.confirmDealCallable = this.functions.httpsCallable('confirmDealCallable');
    this.declineDealCallable = this.functions.httpsCallable('declineDealCallable');

    this.reviewBookingCallable = this.functions.httpsCallable('reviewBookingCallable');

    this.fbProvider = new app.auth.FacebookAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) => {
    GAEvent('auth', email + ' signed up');
    return this.auth.createUserWithEmailAndPassword(email, password);
  }
  doLoginWithEmailAndPassword = (email, password) => {
    GAEvent('auth', email + ' logged in');
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  doLogout = () => {
    GAEvent('auth', 'log out');
    return this.auth.signOut();
  }

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  fbLogin = () => this.auth.signInWithPopup(this.fbProvider).then(result => {
    // This gives you a Facebook Access Token. You can use it to access the Facebook API.
    //var token = result.credential;
    // The signed-in user info.
    //var user = result.user;
    // ...
    if(!this.user(result.user.email)) this.addUserCallable({firstname: '', lastname: '', email: result.user.email});
  }).catch(error => {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // The email of the user's account used.
    var email = error.email;
    // The firebase.auth.AuthCredential type that was used.
    var credential = error.credential;
    // ...
  });

  // *** Storage ***
  storageRef = (ref) => this.storage.ref(ref);
  getDownloadLink = async(mediaFullPath) => {
    const downloadLink = await this.storageRef().child(mediaFullPath).getDownloadURL();
    return downloadLink;
  }

  // *** User API *** 

  user = uid => this.db.doc(`users/${uid}`);
  users = () => this.db.collection('users');

  // *** ColorBoards API ***

  colorboard = id => this.db.doc(`colorboards/${id}`);
  colorboards = () => this.db.collection('colorboards');

  // *** ColorBoards API ***

  deal = id => this.db.doc(`deals/${id}`);
  deals = () => this.db.collection('deals');

  // *** Slots API ***

  slot = id => this.db.doc(`slots/${id}`);
  slots = () => this.db.collection('slots');

  // *** getById ***
  getByID = id => this.db.doc(`${id}`);

  // loadMedia
  loadMedia = async (media) => {
    let sortedMediaData = [];
    if (media) {
      const mediaData = [];
      await Promise.all(media.map(async (m) => {
        let tempData = '';
        await this.getByID(m.path).get().then(snapshot => {
          tempData = snapshot.data();
        })
        let meta = await this.storageRef().child(tempData.fullPath).getMetadata();
        if(!tempData.fileType) tempData.fileType = meta.contentType;
        let downloadLink = await this.storageRef().child(tempData.fullPath).getDownloadURL();
        mediaData.push({url: downloadLink, id: '/' + m.path, ...tempData})
      }))
      sortedMediaData = mediaData.sort((a,b) => {
        if(a.displayname < b.displayname) return -1;
        if(a.displayname > b.displayname) return 1;
        return 0;
      });
    }
    return sortedMediaData;
  }

  loadMediaDirect = async(media) => {
    let mediaData = [];
    await Promise.all(media.map(async(m) => {
      let downloadLink = await this.storageRef().child(m).getDownloadURL();
      mediaData.push({url: downloadLink})
    }))
    return mediaData;
  }
}

export default Firebase;