
import React from 'react';

import { Modal, ModalBody, Button, Col, Row } from 'reactstrap';

const CartAddedModal = (props) => {

  return (
    <Modal isOpen={props.isOpen}>
      <ModalBody>
        <p>Die Buchung wurde zum Warenkorb hinzugefügt!</p>
        <Row>
          <Col>
            <a href="/"><Button color="primary">Weiterer Screen buchen</Button></a>
          </Col>
          <Col>
            <a href="/cart"><Button color="primary" className="float-right">Zum Warenkorb</Button></a>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default CartAddedModal;