import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../../Firebase';
import Loading from '../../Loading';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
        loading: false,
      };
    }

    componentDidMount = async() => {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        async(authUser) => {
          this.setState({loading: true})
          if (authUser) {
            let userDocRef = await this.props.firebase.user(authUser.uid).get();
            let counter = 0;
            while(!userDocRef.exists && counter < 10) {
              await new Promise(r => setTimeout(r, 1500)); // wait for 1 second if the user has just signed up and the document creation is still in progress
              userDocRef = await this.props.firebase.user(authUser.uid).get();
              counter++;
            }
            this.props.firebase.auth.currentUser.getIdTokenResult()
            .then((idTokenResult) => {
              this.setState({authUser: {...authUser, claims: idTokenResult.claims}});
            })
            .catch((error) => {
              console.log(error);
            });
          } else {
            this.setState({authUser: null})
          }
          this.setState({loading: false})
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          {this.state.loading ? <Loading /> :
          <Component {...this.props} /> }
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;