import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { useRecoilState } from "recoil";
import { gettingStartedOpenState } from "../_recoil/atoms.js"

import { AuthUserContext, withAuthorization, withAuthentication } from '../_auth/Session';
import { withFirebase } from '../Firebase';

import { GAEvent } from '../GA/tracker';

import Intro from './intro';
import Screens from './screens'
import SelectMedia from './selectMedia';
import SelectSlot from './selectSlot';
import ConfirmBooking from './confirmBooking';
import Bookings from './bookings';
import Help from './help';

const GettingStartedBase = (props) => {

  const [ gettingStartedOpen, setGettingStartedOpen ] = useRecoilState(gettingStartedOpenState);
  const [ progress, setProgress ] = useState(0);
  const [ title, setTitle ] = useState("Wilkommen bei BiddAd");

  const updateProgress = (newProgress) => {
    let newTitle = "Wilkommen bei BiddAd"
    switch(newProgress) {
      case 1:
        newTitle = newTitle + " - Screens";
        break;
      case 2:
        newTitle = newTitle + " - Medium auswählen";
        break;
      case 3:
        newTitle = newTitle + " - Slot auswählen";
        break;
      case 4:
        newTitle = newTitle + " - Buchung bestätigen";
        break;
      case 5:
        newTitle = newTitle + " - Bookings";
        break;
      case 6:
        newTitle = newTitle + " - Help";
        break;
    }
    GAEvent('GettingStarted', 'ProgressUpdated', newTitle);
    setTitle(newTitle);
    setProgress(newProgress);
  }

  const closeModal = () => {
    setGettingStartedOpen(false);
    GAEvent('GettingStarted', 'Closed');
    props.authUser.gettingStarted && props.firebase.user(props.uid).update({gettingStarted: false});
  }

  useEffect(() => {
    props.authUser.gettingStarted && setGettingStartedOpen(true);
  }, [])

  return (
    <Modal isOpen={gettingStartedOpen} size="lg">
      <ModalHeader toggle={() => closeModal()}>{title}</ModalHeader>
      <ModalBody>
        <br />
        { progress == 0 ? <Intro /> : progress == 1 ? <Screens /> : progress == 2 ? <SelectMedia/> : progress == 3 ? <SelectSlot /> : progress == 4 ? <ConfirmBooking /> : progress == 5 ? <Bookings/> : <Help />}
      </ModalBody>
      <ModalFooter>
        <Row style={{"minWidth": "100%"}}>
          <Col>
            {progress ? <Button color="primary" outline onClick={() => updateProgress(progress-1)}>back</Button> : <p></p>}
          </Col>
          <Col>
            {progress ? <small className="text-center">{progress}/6</small> : <p></p>}
          </Col>
          <Col>
            {progress == 0 ? <Button color="primary" className="float-right" onClick={() => updateProgress(progress+1)} >start</Button> 
            : progress == 6 ? <Button color="primary" className="float-right" onClick={() => closeModal()} >finish</Button> :
            <Button color="primary" className="float-right" onClick={() => updateProgress(progress+1)} >next</Button> }
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

const condition = authUser => !!authUser;

const GettingStarted = compose(
  withAuthorization(condition)
)(GettingStartedBase)

export default GettingStarted;