import React, { useState, useEffect } from 'react';
import "video-react/dist/video-react.css"; // import css
import { Player } from 'video-react';

import { Alert, Button, Input, Row, Col, Table, Card, CardBody } from "reactstrap";

import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization } from '../_auth/Session';
import MediaUpload from '../MediaUpload'

import { GAEvent } from '../GA/tracker';

import {literals} from '../../_constants/literals'

import { useRecoilState } from "recoil";
import { userMediaState, mediaUploadOpenState } from "../_recoil/atoms.js";

import Loading from '../Loading';
const MediaSelectorBase = (props) => {

  const isClient = typeof window === 'object';

  const getSize = () => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [loading, setLoading ] = useState(false);
  const [templateData, setTemplateData ] = useState();
  // const [mediaData, setMediaData ] = useState();
  const [windowSize, setWindowSize] = useState(getSize);

  const [ userMedia, setUserMedia ] = useRecoilState(userMediaState);

  const [ mediaUploadOpen, setMediaUploadOpen ] = useRecoilState(mediaUploadOpenState);
  
  const loadFirebaseData = async() => {
    setLoading(true);
    let mData = [];
    if(!(props.user.media === undefined || props.user.media === 0)) {
      if(!userMedia) {
        mData = await props.firebase.loadMedia(props.user.media);
        setUserMedia(mData);
      }
    }
    
    if (props.template) {
      let tD = await props.firebase.loadMedia([props.template]);
      setTemplateData(tD[0])
    }
    setLoading(false)
  }

  const handleChange = (event) => {
    GAEvent('Book', 'MediaSelected');
    const i = event.target.value
    if (userMedia.findIndex(m => m.id == i) !== -1) {
      props.setSelectedMedia(i);
    } else {
      props.setSelectedMedia(null);
    }
  }

  useEffect(() => {
    loadFirebaseData();
  }, [])

  useEffect(() => {
    if (!isClient) {
      return false;
    }
    function handleResize() {
      setWindowSize(getSize());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return (
    <>
      {loading ? (<Loading />) : (
      <div>
        <Row>
          <Col md="12" lg="6" className="my-2">
            {props.selectedMedia ? (
                <div>
                {userMedia[userMedia.findIndex(m => m.id == props.selectedMedia)] && userMedia[userMedia.findIndex(m => m.id == props.selectedMedia)].fileType === 'video/mp4' ? (
                  <Player className="img-fluid" style={{maxHeight: windowSize.height/2}}>
                    <source src={userMedia[userMedia.findIndex(m => m.id == props.selectedMedia)].url} />
                  </Player>
                ) : (
                  <img id="media" className="img-fluid" style={{maxHeight: windowSize.height/2}} src={userMedia[userMedia.findIndex(m => m.id == props.selectedMedia)].url}></img>
                )}
              </div>              
            ) : (
              templateData ? <img className="img-fluid" id="template" src={templateData.url} style={{maxHeight: windowSize.height/2}}></img> : <svg width="100%" height="300" id="svg" style={{background: 'grey'}} />
            )}
          </Col>
          <Col md="12" lg="6" className="my-2">
              <Table>
                <tbody>
                  <tr>
                    <td>{literals.selectMediaResolution}</td>
                    <td className="text-secondary">{props.screenDimensions.x + " x " + props.screenDimensions.y + " px"}</td>
                  </tr>
                  <tr>
                    <td>{literals.selectMediaOrientation}</td>
                    <td className="text-secondary">{props.screenDimensions.x < props.screenDimensions.y ? (<div><i className="fas fa-portrait"></i><span> {literals.portrait}</span></div>) : (<div><i className="fas fa-image"></i><span> {literals.landscape}</span></div>)}</td>
                  </tr>
                  {props.restrictions && 
                  <tr>
                    <td>{literals.selectMediaRestrictions}</td>
                    <td className="text-secondary">{props.restrictions.text}</td>
                  </tr>
                  }
                </tbody>
              </Table>
              {userMedia && !!userMedia.length && 
              <div>
                <Input type="select" value={props.selectedMedia} onChange={handleChange}>
                    <option value={'empty'}>{literals.selectMedia}</option>
                    {userMedia.map((m, index) => {
                      if(m.fileType.includes('image')) {
                        if (props.restrictions.image) {
                          // make sure to only allow correct resolutions
                          if (m.dimensions && m.dimensions.width === props.screenDimensions.x && m.dimensions.height === props.screenDimensions.y) {
                            return <option key={m.id} value={m.id}>{m.displayname}</option>
                          }
                        }
                      }
                      if(m.fileType.includes('video')) {
                        if(props.restrictions.video) {
                          return <option key={m.id} value={m.id}>{m.displayname}</option>
                        }
                      }
                    })}
                </Input>
                <small><em>Es werden nur Bilder angezeigt, welche die korrekte Auflösung aufweisen.</em></small>
              </div>
              // ) : (
              // <Alert color="info">
              //   Es wurden noch keine Medien hochgeladen.
              // </Alert>
              //<br/>
              }
              <Button color="primary" className="float-right" onClick={() => {GAEvent('Book', 'NewMedia'); setMediaUploadOpen(true)}}>Neues Medium</Button>
          </Col>
        </Row>
        <MediaUpload setSelectedMedia={props.setSelectedMedia} resolution={{width: props.screenDimensions.x, height: props.screenDimensions.y}} 
          image={props.restrictions ? props.restrictions.image : true} video={props.restrictions ? props.restrictions.video : true} />
      </div>
      )}
    </>
  )
}

const MediaSelector = withFirebase(MediaSelectorBase);

export default MediaSelector;
  
