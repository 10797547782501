import React from 'react';
import { Link } from 'react-router-dom';

import { Button, Nav, Navbar, NavbarBrand, NavbarToggler, Collapse, NavItem } from 'reactstrap';

import { AuthUserContext } from '../_auth/Session';

import GettingStarted from '../GettingStarted';

import * as ROUTES from '../../_constants/routes';
import Firebase, { FirebaseContext } from '../Firebase';

import { GAModal, GAEvent } from '../GA/tracker';

import Help from '../Help';
import CartBadge from '../Cart/cartBadge';

const initialState = {
  isOpen: false,
  development: false,
  background: "#0D1015",
  helpOpen: false,
}

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState }
  }

  componentDidMount = () => {
    if (process.env.REACT_APP_BIDDAD_ENV === 'development') this.setState({development: true, background: "red"});
  }

  toggle = () => {
    this.setState({isOpen: !this.state.isOpen});
  }

  toggleHelpOpen = () => {
    !this.state.helpOpen && GAModal('help');
    this.setState({helpOpen: !this.state.helpOpen});
  }

  render() {
    const {background, development} = this.state;
    return (
      <AuthUserContext.Consumer>
        {authUser => 
          authUser ?
          <Navbar expand="md" color="faded" light>
            <GettingStarted />
            <CartBadge uid={authUser.uid} />
            <NavbarBrand href="/"><b style={{color: "#49c5b6", fontSize: "1.5rem"}}>BiddAd</b> <b style={{color: "#49c5b6", fontSize: "0.75rem"}}>easy. fast. flexible</b></NavbarBrand>
            <NavbarToggler onClick={this.toggle}/>
            <Collapse isOpen={this.state.isOpen} navbar>
              { development && <p style={{margin: "0 auto"}}>DEVELOPMENT (don't use) --&gt; <a href="https://app.biddad.com">GO</a></p> }
              <Nav className="ml-auto" navbar>
                <NavItem className="nav-link"><Link to={ROUTES.ROOT}>Screens</Link></NavItem>
                <NavItem className="nav-link"><Link to={ROUTES.MYBOOKINGS}>Bookings</Link></NavItem>
                {authUser.claims && authUser.claims.publisherColorboards &&  authUser.claims.publisherColorboards.length > 0 && <NavItem className="nav-link"><Link to={ROUTES.MYPROPERTY}>Property</Link></NavItem>}
                <NavItem className="nav-link"><Link to={ROUTES.ACCOUNT}>Account</Link></NavItem>
                <FirebaseContext.Consumer>{firebase => (
                  <>
                    <NavItem className="nav-link"><Link to="#" onClick={firebase.doLogout}><i title="logout" className="fas fa-sign-out-alt"></i></Link></NavItem>
                    <NavItem className="nav-link"><Link to="#" onClick={this.toggleHelpOpen}><i title="Hilfe" className="fas fa-question"></i></Link></NavItem>
                  </>
                )}</FirebaseContext.Consumer>
              </Nav>
            </Collapse>
            <Help isOpen={this.state.helpOpen} toggleHelpOpen={this.toggleHelpOpen} />
          </Navbar> :
          <Navbar expand="md" color="faded" light>
            <NavbarBrand href="/"><span style={{textDecoration: "none"}}><b style={{color: "#49c5b6", fontSize: "1.5rem"}}>BiddAd</b> <b style={{color: "#49c5b6", fontSize: "0.75rem"}}>easy. fast. flexible</b></span></NavbarBrand>
            <NavbarToggler onClick={this.toggle}/>
            <Collapse isOpen={this.state.isOpen} navbar>
              
              { development && <p style={{margin: "0 auto"}}>DEVELOPMENT (don't use) --&gt; <a href="https://app.biddad.com">GO</a></p> }
              <Nav className="ml-auto" navbar>
                <NavItem className="nav-link"><Link to={ROUTES.ROOT}>Screens</Link></NavItem>
                <NavItem className="nav-link"><Link to={ROUTES.ACCOUNT}>Login</Link></NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        }
      </AuthUserContext.Consumer>
    )
  }

}

export default Navigation;