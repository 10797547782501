import React from 'react';
import { css } from '@emotion/core';
// First way to import
import { ClipLoader } from 'react-spinners';

const override = css`
    display: block;
    margin: 0 auto;
`;

class Loading extends React.Component {
  constructor(props) {
    super(props);
      this.state = { loading: true }
  }
  render() {
    return (
    <div className='sweet-loading'>
      <ClipLoader
      css={override}
      sizeUnit={"px"}
      size={50}
      color={'#123abc'}
      loading={this.state.loading}
      />
    </div> 
    )
  }
}

export default Loading;