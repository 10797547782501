import React, { useState, useEffect } from 'react';

import moment from 'moment';
import { Button, Row, Col, Card, CardBody } from 'reactstrap';

import Loading from '../Loading';

import {literals} from '../../_constants/literals'

const durationToString = (duration) => {
  let out = '';
  let hours = Math.floor(duration / 3600);
  let minutes = Math.floor(duration%3600/60);
  let seconds = Math.floor(duration%60);

  if (hours) { 
    if (hours > 1) {
      out += String(hours) + ' ' + literals.hours + ' '; 
    } else {
      out += String(hours) + ' ' + literals.hour + ' '; ; 
    }
  }
  if (minutes) {
    if (minutes > 1) {
      out += String(minutes) + ' ' + literals.minutes + ' '; 
    } else {
      out += String(minutes) + ' ' + literals.minute + ' '; ; 
    }
  }
  if (seconds) {
    if (seconds > 1) {
      out += String(seconds) + ' ' + literals.seconds; 
    } else {
      out += String(seconds) + ' ' + literals.second;
    }
  }
  return out;
}

const Slots = (props) => {

  const [ cbData, setCbData ] = useState();
  const [ media, setMedia ] = useState({displayname: null, downloadLink: null});
  const [ slotsData, setSlotsData ] = useState([]); 

  const [ removing, setRemoving ] = useState(false);

  const loadCB = async() => {
    if(props.slot?.colorboard) {
      const cb = await props.slot.colorboard.get();
      setCbData(cb.data());
    }
  }

  const loadSlots = async() => {
    const sData = [];
    await Promise.all(props.slot?.refs?.map(async(sR) => {
      const s = (await sR.get()).data();
      sData.push({
        path: sR.path,
        start: s.start,
        end: s.end,
      })
    }))
    setSlotsData(sData);
  }

  const loadMedia = async() => {
    const mData = (await props.firebase.db.doc(props.slot.media).get()).data();
    const downloadLink = await props.firebase.storageRef().child(mData.fullPath).getDownloadURL();
    setMedia({displayname: mData.displayname, downloadLink});
  }

  const deletePosition = async() => {
    setRemoving(true);
    await props.firebase.db.collection('carts').doc(props.uid).update({
      slots: props.firebase.FieldValue.arrayRemove(props.slot)
    })
    setRemoving(false);
  }

  useEffect(() => {
    loadCB();
    loadMedia();
    loadSlots();
  }, []);

  return(
    <Card className="my-2 shadow">  
      <CardBody>
        <Row>
          <Col>
            Screen: <a href={"/book/" + props.slot?.colorboard?.id}>{cbData?.address}</a>
          </Col>
          <Col>
          {props.editable && (removing ? <Loading /> : <Button color="link" className="btn-thin float-right" onClick={deletePosition}><i className="fas fa-trash-alt"></i></Button>)}
          </Col>
        </Row>
        <Row>
          <Col>
            Medium: {media.displayname && media.downloadLink && <a href={media.downloadLink} target="_blank" rel="noopener noreferrer">{media.displayname}</a>}
          </Col>
          <Col>
            <span className="float-right">{props.slot.total !== props.slot.totalDiscount ? <><strike>{props.slot.total.toFixed(2)}</strike> {props.slot.totalDiscount.toFixed(2)} CHF</> : <>{props.slot.total.toFixed(2)} CHF</>}</span>
          </Col>
        </Row>
        <hr />
        <div className="d-flex flex-wrap pb-2">
          {slotsData && slotsData.map((s, index) => 
            <div key={index} className="shadow-sm p-2 m-1 border rounded">{String(moment(s.start.toDate()).format('DD.MM.YYYY HH:mm'))} - {String(moment(s.end.toDate()).format('HH:mm'))}</div>
          )}
        </div>
        {cbData?.interval &&
          <span>{literals.informationScreenTime}: min. {cbData.stepSize/cbData.interval.rotation}x {literals.each} {durationToString(cbData.interval.playtime)}</span>
        }
      </CardBody>
    </Card>
  )

}

export default Slots;